import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';


export const CSRContainer = styled.div`
${tw`flex justify-center items-center mt-24 flex-col mx-10`}
`

export const Container2 = styled.div`
${tw`flex justify-center items-center flex-col mt-10`}
`

export const TitleSub = styled.h1`
  ${tw` font-bold mt-5 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
`;

export const ImgCont = styled.img`
  ${tw`w-[85%] h-auto rounded-lg`}
`;

export const DescriptionMain = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`;