export const navItems = [
  {
    id: 1,
    title: 'About',
    path: './about',
    cName: 'nav-items',
  },
  {
    id: 2,
    title: 'Products',
    path: './product',
    cName: 'nav-items',
  },
  {
    id: 3,
    title: 'Services',
    path: './services',
    cName: 'nav-items',
  },
  {
    id: 4,
    title: 'Academy',
    path: './Academy',
    cName: 'nav-items',
  },
  {
    id: 5,
    title: 'Careers',
    path: './careers',
    cName: 'nav-items',
  },
  {
    id: 6,
    title: 'Contact us',
    path: './contactus',
    cName: 'nav-items',
  },
];

export const serviceDropDown = [
  {
    id: 1,
    title: 'Team',
    path: './team',
    cName: 'submenu-items',
  },
  {
    id: 2,
    title: 'CSR Activities',
    path: './csr',
    cName: 'nav-items',
  },
  // {
  //   id: 3,
  //   title: 'Testimonials',
  //   path: './testimonials',
  //   cName: 'nav-items',
  // },
];


export default navItems;