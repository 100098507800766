import React from 'react';
import {
  ServiceContainer,
  Container2,
  Content2,
  Description,
  Content3,
  Content3Img,
  Content3Desc,
  TitleSub,
  TitleCont,
  Content3Img2,
  Content3Img2B,
  DescriptionMain,
  ContMain,
  ImgContOne,
  ImgContTwo,
} from '../../styles/ServicesStyle';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

import SD2 from '../../images/SDLanding.jpg';
import DS2 from '../../images/DS.jpg';
import SS from '../../images/aboutImage.jpg';

import ScrollToTop from '../ScrollToTop';

const Services = (scrollToTop, classes, isVisible) => {
  return (
    <ServiceContainer>
      <Container2>
        <Content2>
          <TitleHeadComCont>
            <Title
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            >
              Our Services
            </Title>
            <TitleDash
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <TitleSub>Empowering Your Success, One Service at a Time!</TitleSub>
          <DescriptionMain>
            Welcome to Leapot! We offer a wide range of services to meet your
            needs. Whether you're looking for Custom Software Development,
            Digital Solutions, or Staffing services, we've got you covered. Our
            team of experts is dedicated to delivering top-notch solutions
            tailored to your requirements. Explore our services below
          </DescriptionMain>
        </Content2>
        <ContMain>
          <Content3>
            <Content3Desc>
              <TitleCont>Custom Software Development</TitleCont>
              <Description>
                Transforming Your Vision into Custom Software Solutions, here at
                Leapot, our area of expertise is developing custom software
                suited to your particular demands. Our skilled development team
                is committed to providing innovative, scalable, high-quality
                software solutions. Unlike off-the-shelf software, custom
                software is built from scratch and is fully customizable to
                address specific challenges, improve efficiency, and enhance
                productivity. We offer custom software development to address
                the specific needs and requirements of a business or
                organization. Whether you're a small startup or a large
                enterprise, custom software can be designed to accommodate your
                current needs while allowing for future growth. So what are you
                waiting for feel free to purchase our services.
              </Description>
            </Content3Desc>
            <ImgContOne>
              <Content3Img src={SD2} />
            </ImgContOne>
          </Content3>
          <Content3>
            <Content3Img2 src={DS2} />
            <Content3Desc>
              <TitleCont>Digital Learning Solutions</TitleCont>
              <Description>
                Digital Learning Solutions revolutionize the educational
                landscape by harnessing the power of technology to deliver
                dynamic and engaging learning experiences. Our bespoke solutions
                encompass a wide array of multimedia content, including
                interactive presentations, audiovisual materials, quizzes,
                exams, and microlearning nuggets, meticulously crafted to cater
                to diverse learning styles and objectives. By leveraging
                innovative instructional design principles, we ensure that
                learners are actively engaged and empowered to master concepts
                effectively. Whether you're a student seeking interactive study
                aids or an organization striving to upskill your workforce, our
                Digital Learning Solutions provide the tools and resources
                necessary to achieve educational excellence in the digital age.
                Join us in shaping the future of learning through immersive and
                tailored digital experiences.
              </Description>
            </Content3Desc>
            <ImgContTwo>
              <Content3Img2B src={DS2} />
            </ImgContTwo>
          </Content3>
          <Content3>
            <Content3Desc>
              <TitleCont>Staffing Services</TitleCont>
              <Description>
                Greetings from Leapot, your go-to resource for all-inclusive
                staffing solutions. Our specialty is bringing top personnel from
                a variety of sectors and roles together with businesses. We have
                the experience to handle your staffing needs, whether you're
                searching for temporary employees, permanent hires, or
                specialized professionals. You can view our employment
                offerings. We offer staffing services specialized in sourcing,
                screening, and selecting candidates from different industries
                and job functions. These services offer staffing solutions
                according to the changing needs of the business. This will
                modernize the hiring process by advertising for the job
                position, candidate profiles, resume screening, interviews,
                reference checks, background verification, etc. So, Unclose Your
                Workforce Potential with Our Premier Staffing Solutions!
              </Description>
            </Content3Desc>
            <ImgContOne>
              <Content3Img src={SS} />
            </ImgContOne>
          </Content3>
          <Content3>
            <Content3Img2 src={DS2} />
            <Content3Desc>
              <TitleCont>Other Educational Services</TitleCont>
              <Description>
                Explore our array of supplementary educational services tailored
                to enrich teaching and learning experiences. From curriculum
                development and learning management system integration to
                professional development workshops and educational consulting,
                we offer comprehensive support to educators, institutions, and
                learners alike.
                <br />
                <br />
                Elevate your educational initiatives with our expertise and
                innovative solutions.
              </Description>
            </Content3Desc>
            <ImgContTwo>
              <Content3Img2B src={DS2} />
            </ImgContTwo>
          </Content3>
        </ContMain>
      </Container2>
      <ScrollToTop
        isVisible={isVisible}
        scrollToTop={scrollToTop}
        classes={classes}
      />
    </ServiceContainer>
  );
};

export default Services;
