import React, { useEffect } from 'react';
import {
  AcademyContainer,
  MainContainer,
  AcademyImg,
  SubContainer1,
  MainParagraph,
  Paragraph,
  SubContainer2,
  GridCardContainerSub,
  GridHeading,
  GridSubHeading,
  GridCardContainer,
  SubContainer3,
  Heading,
  InnerContainer,
  InnerSubContainer,
  Header,
  Description,
  SubContainer4,
  InnerContainer2,
  AcademyImage,
  Container4Heading,
  Container4Paragraph,
  AcademyButton,
  NavLink,
  SubContainer5,
  Container5Heading,
  InnerContainer5,
  InnerSubContainer5,
  Container5Header,
  Container5Description,
  Container5Image,
  AcademyCardStyle,
  AcademyImage2,
  TitleHead,
  ExploreBtn,
  AnchorTag,
} from '../styles/AcademyStyle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import academy from '../images/academy2.png';
import courses from '../images/AcadamyImages/Courses.png';
import leapotAcademy from '../images/AcadamyImages/leapotAcademy.png';
import academyCourses from '../images/AcadamyImages/AcademyCourses.png';
// import academyContact from '../images/AcadamyImages/academyContact.png';
import academyRegister from '../images/AcadamyImages/academyRegister.png';
import academyBuyCourse from '../images/AcadamyImages/academyBuyCourses.png';
import Testimonials from '../components/Header/Testimonials';

import ScrollToTop from '../components/ScrollToTop';

import { Title, TitleDash } from '../styles/CommonStyles';
import { palette } from '../styles/palette';

import AOS from 'aos';
import 'aos/dist/aos.css';

const AcademyPage = (scrollToTop, classes, isVisible) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const AcademyData = [
    {
      title: 'Orientation Training',
      description:
        'This course will cover the key aspects of the organization, Company Policies and Procedures, Workplace Culture, Job Roles and Responsibilities,  Technology and Resources,  and Networking.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Onboarding Training',
      description:
        'A thorough program called an onboarding training course, also known as employee onboarding, is designed  which aims to make them productive, involved, and well-connected team members.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Compliance Training',
      description:
        'A compliance training course is a customized curriculum created to inform staff members on the laws, rules, guidelines, and policies that apply to their business or sector.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Product Training',
      description:
        'The objective of product training is to guarantee that the employees possess a comprehensive understanding of the characteristics, benefits, uses, and value propositions of the product.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Leadership Training',
      description:
        'The main purpose of the Leadership Training Course is giving team members the information, abilities, and resources they need to successfully inspire, encourage, and lead  towards corporate goals ',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Cyber Security Essentials Training',
      description:
        'This program will provide participants with the skills necessary for an understanding of cyberthreats, vulnerabilities, and defend their businesses and themselves against cyberattacks.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Quality Assurance (Q/A) Training',
      description:
        'This course is designed to equip individuals with the knowledge, skills, and tools needed to ensure that products or services meet established quality standards and customer expectations. ',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Sales Training',
      description:
        'A Sales Training course is a specialized program designed to enhance the selling skills, techniques, and strategies of individuals involved in sales roles within an organization.',
      MoreInfo: 'Explore Collection',
    },
    {
      title: 'Soft Skills Training',
      description:
        'This training course is a particular program which focuses on developing non-technical skills, that are essential for effective communication, collaboration, and leadership.',
      MoreInfo: 'Explore Collection',
    },
  ];
  return (
    <>
      <AcademyContainer>
        <TitleHead>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="300"
            data-aos-delay="150"
          >
            Academy
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="300"
            data-aos-delay="150"
          />
        </TitleHead>
        <MainContainer>
          <AcademyImg src={academy} alt="" />
          <SubContainer1>
            <MainParagraph>
              Empowering Minds, Igniting Futures: Your Journey Starts Here at
              Leapot LMS
            </MainParagraph>
            <Paragraph>
              Leapot Academy is an online learning platform dedicated to
              providing high-quality courses and educational resources. It is
              dedicated to providing high-quality online courses and educational
              resources to help you expand your knowledge and skills in various
              fields. Our mission is to empower individuals to learn new skills,
              advance their careers, and achieve personal growth. Below is an
              overview of the academy, including its mission, vision, and
              values. Highlight the key features that make the academy unique
              and why visitors should choose it for their learning needs.
            </Paragraph>
          </SubContainer1>
        </MainContainer>
        <SubContainer2>
          <GridHeading>Enhance Your Education with Leapot Academy</GridHeading>
          <GridSubHeading>
            Put your workforce and training back on track with Leapot's pre-made
            courses
          </GridSubHeading>
          <GridCardContainerSub>
            <GridCardContainer>
              {AcademyData.map((item, index) => (
                <Card
                  sx={AcademyCardStyle}
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="700"
                  data-aos-delay="300"
                >
                  <CardContent>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        color: `${palette.primary}`,
                        fontSize: '22px',
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{ marginTop: '10px', fontSize: '15px' }}
                      variant="body2"
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <NavLink
                      href="https://leapottechnologies.graphy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExploreBtn>{item.MoreInfo}</ExploreBtn>
                    </NavLink>
                  </CardActions>
                </Card>
              ))}
            </GridCardContainer>
          </GridCardContainerSub>
        </SubContainer2>
        <SubContainer3>
          <Heading>Why Choose Leapot Academy?</Heading>
          <InnerContainer>
            <InnerSubContainer
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="700"
              data-aos-delay="300"
            >
              <Header>Online Learning Platform</Header>
              <Description>
                It is dedicated to providing high-quality online courses and
                educational resources to help you expand your knowledge and
                skills in various fields
              </Description>
            </InnerSubContainer>
            <InnerSubContainer
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="700"
              data-aos-delay="300"
            >
              <Header>AI-Exam Gaurd</Header>
              <Description>
                With the power of AI, our quiz and exam question generator
                ensures diverse and engaging content, enriching the learning
                process for students.
              </Description>
            </InnerSubContainer>
            <InnerSubContainer
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="700"
              data-aos-delay="300"
            >
              <Header>Experts’ Evaluation</Header>

              <Description>
                Every course is reviewed by senior instructional designers where
                every topic is thoroughly investigated utilizing the most recent
                data specialists.
              </Description>
            </InnerSubContainer>
          </InnerContainer>
        </SubContainer3>
        <SubContainer4>
          <AcademyImage
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-delay="300"
            src={courses}
            alt=""
          />
          <InnerContainer2>
            <Container4Heading>
              New Courses Released Every Week
            </Container4Heading>
            <Container4Paragraph>
              Stay Ahead of the Curve: Discover a New Course
              <br></br>
              Every Week at Leapot Academy!
            </Container4Paragraph>
          </InnerContainer2>
          <AcademyImage2 src={courses} alt="" />
        </SubContainer4>

        <SubContainer5>
          <Container5Heading>
            Make a powerful impact with your internal training program with
            Leapot LMS and Leapot Academy subscription
          </Container5Heading>

          <InnerContainer5>
            <InnerSubContainer5>
              <Container5Header>Step 1</Container5Header>
              <Container5Description>
                Visit Leapot Academy to boost your Education
              </Container5Description>
              <Container5Image src={leapotAcademy} alt="" />
              <AnchorTag
                href="https://leapottechnologies.graphy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AcademyButton>
                  <NavLink target="_blank" rel="noopener noreferrer">
                    Visit
                  </NavLink>
                </AcademyButton>
              </AnchorTag>
            </InnerSubContainer5>
            <InnerSubContainer5>
              <Container5Header>Step 2</Container5Header>
              <Container5Description>
                Peruse Our Course Offerings
              </Container5Description>
              <Container5Image src={academyCourses} alt="" />
              <AnchorTag
                href="https://leapottechnologies.graphy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AcademyButton>
                  <NavLink target="_blank" rel="noopener noreferrer">
                    Visit
                  </NavLink>
                </AcademyButton>
              </AnchorTag>
            </InnerSubContainer5>
            <InnerSubContainer5>
              <Container5Header>Step 3</Container5Header>
              <Container5Description>
                To advance your career, Register for free
              </Container5Description>
              <Container5Image src={academyRegister} alt="" />
              <AnchorTag
                href="https://leapottechnologies.graphy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AcademyButton>
                  <NavLink target="_blank" rel="noopener noreferrer">
                    Visit
                  </NavLink>
                </AcademyButton>
              </AnchorTag>
            </InnerSubContainer5>
            <InnerSubContainer5>
              <Container5Header>Step 4</Container5Header>
              <Container5Description>
                Purchase Career-Focused Courses
              </Container5Description>
              <Container5Image src={academyBuyCourse} alt="" />
              <AnchorTag
                href="https://leapottechnologies.graphy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AcademyButton>
                  <NavLink target="_blank" rel="noopener noreferrer">
                    Visit
                  </NavLink>
                </AcademyButton>
              </AnchorTag>
            </InnerSubContainer5>
          </InnerContainer5>
        </SubContainer5>
        <div style={{ marginTop: '200px' }}></div>
        <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        />
      </AcademyContainer>
      <Testimonials />
    </>
  );
};
export default AcademyPage;
