import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';

export const LandingHeading = styled.p`
  ${tw`text-5xl font-bold`}
  color : ${palette.white}
`;

export const TypographyStyle = {
  fontSize: '1.875rem',
  fontWeight: ' 500',
};



export const Title = styled.h1`
  ${tw` font-bold text-lg 2xl:text-3xl xl:text-3xl lg:text-3xl md:text-xl sm:text-lg leading-4 mt-[-80px] `}
  color: ${palette.primary};
`;

export const TitleHeadComCont = styled.div`
  ${tw`flex justify-center items-center flex-col mt-20`}
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[100%] rounded-lg mb-5`}
  background:${palette.primary};
  border: none;
`;

export const MenuHeading = styled.p`
fontStyle : normal
fontWeight : 400
lineHeight : 70px
`;

export const PageHeading = styled.p``;
export const MainHeading = styled.p``;
export const MainContent = styled.p``;
export const Content = styled.p``;
export const Text = styled.p``;
export const CardHeading = styled.p``;
export const CardContent = styled.p``;
