import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
  MainContainer,
  Header,
  Desc,
  ContactForm,
  TextFieldStyle,
  SubmitButton,
  TextFieldMsg,
  TextFieldMsgStyle,
  ContactDescMain,
  ContactIconImage,
  TextFieldMain,
  PhoneInputContainer,
} from '../../styles/ContactStyles';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { palette } from '../../styles/palette';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

import AOS from 'aos';
import 'aos/dist/aos.css';

import ContactIcon from '../../images/ContactIcon.png';

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [state, setState] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === '') {
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'email' && !validateEmail(value)) {
      alert('Please enter a valid email address.');
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'name' && !validateName(value)) {
      alert('Please enter alphabets only in the name field.');
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newErrorState = {
      name: state.name === '',
      email: state.email === '' || !validateEmail(state.email),
      mobile:
        state.mobile === '' || state.mobile.replace(/\D/g, '').length === 0,
      message: state.message === '',
    };

    setErrorState(newErrorState);

    if (Object.values(newErrorState).some((field) => field)) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://leapot-lms-backend.onrender.com/api/addContactUs',
        {
          name: state.name,
          email: state.email,
          mobile: state.mobile,
          subject: state.subject,
          message: state.message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data && response.data.message === "Contact form submitted successfully") {
        alert('Your details have been forwarded to the concerned team.');
        setErrorState({
          name: false,
          email: false,
          mobile: false,
          // subject: false,
          message: false,
        });
        setState({
          name: '',
          email: '',
          mobile: '',
          // subject: '',
          message: '',
        });
      } else {
        alert('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <TitleHeadComCont>
        <Title
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
          data-aos-delay="150"
        >
          Contact
        </Title>
        <TitleDash
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
          data-aos-delay="150"
        />
      </TitleHeadComCont>
      <MainContainer>
        <ContactDescMain>
          <ContactIconImage
            src={ContactIcon}
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-delay="50"
          />
          <Header
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-delay="50"
          >
            Contact us
          </Header>
          <Desc
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-delay="50"
          >
            Got questions? We've got answers. Reach out and let's chat!{' '}
          </Desc>
        </ContactDescMain>

        <ContactForm
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
          data-aos-delay="50"
        >
          <TextFieldMain>
            <TextField
              required
              label="Enter Name"
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder="Name"
              sx={TextFieldStyle}
              error={errorState.name}
              value={state.name}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, name: e.target.value })}
              helperText={
                errorState.name
                  ? 'Please enter alphabets only in the name field.'
                  : ''
              }
            />
            <TextField
              required
              label="Enter Email"
              type="text"
              name="email"
              id="email"
              variant="outlined"
              placeholder="Email"
              sx={TextFieldStyle}
              error={errorState.email}
              value={state.email}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              helperText={
                errorState.email ? 'Please enter a valid email address.' : ''
              }
            />
            <PhoneInputContainer error={errorState.mobile}>
              <PhoneInput
                country={'in'}
                value={state.mobile}
                onChange={(mobile) => setState({ ...state, mobile })}
                countryCodeEditable={false}
                onBlur={handleBlur}
              />
              {errorState.mobile && (
                <span style={{ color: 'red', fontSize: '0.75rem' }}>
                  Please enter a mobile number.
                </span>
              )}
            </PhoneInputContainer>
          </TextFieldMain>
          <TextFieldMsg>
            <TextField
              required
              multiline
              rows={4}
              name="message"
              variant="outlined"
              placeholder="Type Message..."
              error={errorState.message}
              value={state.message}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, message: e.target.value })}
              sx={TextFieldMsgStyle}
            />
          </TextFieldMsg>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            style={SubmitButton}
            sx={{
              '& .MuiButtonBase-root': {
                backgroundColor: palette.secondary,
              },
            }}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </ContactForm>
      </MainContainer>
    </>
  );
};

export default Contact;
