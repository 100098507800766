import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';
import ContactBgImage from '../images/Contactus.jpg';

export const ContactContMain = styled.div`
  ${tw`flex justify-center items-center flex-col m-10 rounded-lg mt-48
sm:m-20 sm:mt-[175px]
`}// border:2px solid ${palette.primary}
// overflow: hidden;

// background-image: url(${ContactBgImage});
// background-size: cover;
// background-position: center;
`;

export const Desc = styled.p`
  ${tw`flex justify-center items-center text-center 2xl:text-xl xl:text-lg lg:text-base mt-5 leading-8 `}
`;

export const ContactFormMain = styled.div`
  ${tw`flex justify-center items-center  flex-col  h-auto w-[100%] `}
`;

export const ContactFormCont = styled.div`
  ${tw`flex justify-center items-center flex-col w-[40%] h-[700px] rounded-xl `}
`;
export const ContactFormMainsub = styled.div`
  ${tw`flex justify-center items-center h-auto w-[100%]`}
`;

export const ContactFormCont2 = styled.div`
  ${tw`flex justify-center items-center  flex-col h-[10%] mt-10  w-[80%]`}
`;

export const ContactImg = styled.img`
  ${tw`hidden w-[60%] xl:w-[50%] h-auto  mr-10 
lg:block
`}
`;

export const SubmitButton = styled.button`
  ${tw`p-3 w-[100%] lg:w-[70%] mt-10  h-auto flex cursor-pointer justify-center text-white text-xl items-center rounded-lg`}
  border:none;
  background:${palette.secondary}
`;


export const TextFieldStyle = {
  marginTop: '50px',
  '& .MuiInputBase-root': {
    fontSize: '1rem',
    background: `${palette.white}`,
    height: '45px',
    marginTop: '5px',
    maxWidth: '100%',
    width: '300px',
  },
  '@media (min-width: 768px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 992px)': {
    '& .MuiInputBase-root': {
      width: '400px',
    },
  },
  '@media (min-width: 1200px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 1440px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
};


export const PhoneInputContainer = styled.div`
${tw`mt-10 w-[100%]`}
  & .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-bottom: 0 !important;
    // padding-left: 48px;
    margin-left: 10px;
    background: #FFFFFF;
    // border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 50px; /* Changed height to 50px */
    width: 100%;
    outline: none;

    border: 1px solid ${({ error }) => (error ? 'red' : '#ced4da')};
  }



  & .react-tel-input {
    width: 100%;
    margin-left: -6px;
    // border-left: 1px solid ${({ error }) => (error ? 'red' : '#ced4da')};
    // border-bottom: 1px solid ${({ error }) => (error ? 'red' : '#ced4da')};
    border-radius: 5px;
  }

  @media (min-width: 280px) {
    width: 290px;
  }

  @media (min-width: 375px) {
    width: 290px;
  }

  @media (min-width: 640px) {
    width: 290px;
  }
  
  @media (min-width: 768px) {
    width: 490px;
  }

  @media (min-width: 992px) {
    width: 390px;
  }

  @media (min-width: 1200px) {
    width: 490px;
  }

  @media (min-width: 1440px) {
    width: 490px;
  }

`;


export const Title2 = styled.h2`
  ${tw` text-lg 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl sm:text-lg leading-4 `}
  color:${palette.primary}
`;


export const Desc2 = styled.p`
  ${tw`text-sm text-lg mt-2
  md:text-xl
  `}
`;


export const TitleCont = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const TitleContB = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const ContSub2 = styled.div`
  ${tw`flex justify-around items-center flex-col w-[35%] m-10 `}
`;

export const ContSub2B = styled.div`
  ${tw`flex justify-around items-center flex-col w-[35%] m-10 `}
`;

export const ContSub2Main = styled.div`
  ${tw`flex justify-center items-center w-[70%] sm:w-[100%] `}
  `;

export const ContSub2MainB = styled.div`
  ${tw`flex justify-center items-center w-[100%] mt-[-50px]
  lg:mt-[10px]
  `}
`;

export const ContactFormCont3 = styled.div`
  ${tw`flex justify-center  items-center flex-col w-[100%]
  lg:flex-row 
  `}
`;


export const TitleLogo = styled.img`
  ${tw`w-8 h-auto mx-3`}
`;

export const TitleDash2 = styled.hr`
  ${tw`h-[2px] w-[90%] rounded-lg mb-5`}
  background:${palette.primary}
`;
