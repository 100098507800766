import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";


export const MainContainer = styled.div`
  ${tw`flex justify-center items-center flex-col md:flex-row mx-20 mt-10 mb-40`}
  // border:2px solid red;
  // background: linear-gradient(to bottom, ${palette.buttonhover}, ${palette.primary}); /* Adjust colors as needed */
`;

export const Heading = styled.h1`
${tw`text-xl md:text-2xl lg:text-3xl xl:text-4xl mt-20`}
color:${palette.primary}
`




export const Heading2 = styled.h1`
${tw`text-xl md:text-2xl lg:text-3xl xl:text-4xl font-medium`}
color:${palette.buttonhover}
`

export const Heading3 = styled.h1`
${tw`text-base sm:text-lg font-medium text-red-600`}
`

export const Heading4 = styled.h1`
${tw`text-lg sm:text-xl md:text-xl`}
color:${palette.primary}
`

export const Heading5 = styled.h1`
${tw`text-lg sm:text-xl md:text-xl mt-10 text-white`}
color:${palette.primary}
`

export const Container1 = styled.div`
${tw`flex justify-center items-center flex-col`}
`

export const Container2 = styled.div`
${tw``}
`

export const Container2A = styled.div`
${tw`flex justify-center items-start mt-5 flex-col md:flex-row `}
`

export const Container2AB = styled.div`
${tw`hidden flex justify-center items-start flex-col mr-10`}
`


export const Container2B = styled.div`
${tw`flex justify-center items-start flex-col w-[70vw]`}
`

export const Container2C = styled.div`
${tw`flex justify-start items-start flex-col`}

`
export const Container2CB = styled.div`
${tw`flex justify-start items-start flex-col md:ml-10`}
`

export const Container2CMain = styled.div`
${tw`flex justify-start items-start flex-col md:flex-row`}
`

export const FieldLabel = styled.label`
${tw`mt-2 mr-5 flex text-lg justify-start font-bold items-center`}
// color:${palette.buttonhover}
`
  

export const FieldLabel2 = styled.label`
${tw` flex justify-start items-center`}
`


export const FiledCont = styled.div`
${tw``}
`

export const Submitbtn = styled.button`
${tw`p-3 w-[50%] flex mt-16  justify-center items-center rounded-lg border-none text-white text-lg`}
background-color:${palette.secondary}
`

export const InputFiledStyle = {
  // backgroundColor:'red',
  maxWidth:'600px',
  width:'100%',

  '@media (max-width: 600px)': { // Small screens
    maxWidth: '300px',
  },
  '@media (min-width: 601px) and (max-width: 960px)': { // Medium screens
    maxWidth: '500px',
  },
  '@media (min-width: 961px) and (max-width: 1280px)': { // Large screens
    maxWidth: '400px',
  },
  '@media (min-width: 1281px)': { // Extra-large screens
    maxWidth: '550px',
  },
  '@media (min-width: 1500px)': { // Extra-large screens
    maxWidth: '400px',
  },

}


export const UploadFileBtn = styled.button`
${tw`text-white p-2 rounded cursor-pointer`}
background:${palette.secondary};
border:none
`

export const RemoveButton = styled.button`
${tw`text-lg text-white font-bold cursor-pointer rounded-full w-7 mx-2`}
background:red;
border:none
`

export const RemoveButtonCont = styled.div`
${tw`mx-3`}
`
