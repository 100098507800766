import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from 'axios';

import {
  CardsStyle,
  CardDesc,
  CardTitle,
  CardDescCont,
} from '../../styles/TestimonialStyle';

export default function TestimonialCard() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://leapot-lms-backend.onrender.com/api/getTestimonData')
      .then(response => {
        if (!response.data.Testimonials) {
          throw new Error('Testimonials data is missing');
        }
        setTestimonials(response.data.Testimonials);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Grid
      container
      spacing={5}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <p>Loading testimonials...</p>
      ) : (
        testimonials.map((testimonial, index) => (
          <Grid
            style={{
              marginBottom: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            key={index}
          >
            <Card sx={CardsStyle}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <CardMedia
                  sx={{
                    height: '250px',
                    width: '250px',
                    aspectRatio: 3 / 2,
                    borderRadius: '100%',
                    margin: '5px',
                    border: '3px solid #036ACB',
                    pointerEvents: 'none',
                  }}
                  image={testimonial.image}
                />
                <Typography
                  style={CardTitle}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {testimonial.author}
                </Typography>
              </div>
              <CardContent style={CardDescCont}>
                <Typography
                  sx={CardDesc}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {testimonial.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
}
