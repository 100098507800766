import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";
import { Typography } from "@mui/material";

export const CardStyle = {
  boxShadow: 'none',
  cursor: 'pointer',
  // backgroundColor:'red',

  '&:hover': {
    // Add styles you want to apply on hover
    // For example:
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    transition: 'box-shadow 0.3s ease',
    backgroundColor: 'blue',
  }
};



export const MainTeamContainer = styled.div`
  ${tw`mt-[120px] `}
`;

export const TitleContMain = styled.div`
  ${tw`flex justify-center items-center mt-[-30px]`}
`;


export const TeamDescStyle = styled(Typography)`
${tw`font-thin mt-5 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
color: ${palette.black};
`;


export const HeadingAnimation = `
  @keyframes slideInHeading {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideInHr {
    0% {
      width: 0;
    }
    100% {
      width: 400px;
    }
  }
`;

export const ImgCont = styled.div`
  ${tw` `}
`;

export const CardIMG = styled.div`
  ${tw`absolute top-0 left-0 w-[200px] h-full bg-white mix-blend-multiply`}
`;

export const TeamHeadMain = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const ContainerB = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const GridContainer = styled.div`
  ${tw`flex justify-center items-center mr-0
  sm:mr-24
  `}
`;

export const TeamHeading = styled.h1`
  ${tw`text-4xl font-bold`}
  color : ${palette.primary}
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[20%] rounded-lg mb-5`}
  background:${palette.primary}
`;

export const TeamsDesc = styled.p`
  ${tw`font-thin mt-5 mx-10 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`
