import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';

export const CareersCont = styled.div`
${tw`flex justify-center items-center flex-col mt-24 mx-10 mb-40`}
`

export const CareersTitle = styled.h1`
${tw`font-medium mt-5 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
`

export const CareersTitle2 = styled.h1`
${tw`font-medium mt-3 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
color: ${palette.white};
`

export const CareersParagraph = styled.p`
  ${tw`font-thin text-lg 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-sm   tracking-wide`}
  color: ${palette.black};
`

export const CareersParagraph2 = styled.p`
  ${tw`font-thin text-lg 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8 w-[100%]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-sm   tracking-wide`}
  color: ${palette.white};
`

export const Container1 = styled.div`
${tw`flex justify-center items-center flex-col w-[100%] p-2`}
`

export const Container2 = styled.div`
${tw`flex justify-center items-center flex-col w-[95%] mx-3`}
background:${palette.primary}
`

export const Container3 = styled.div`
${tw`flex justify-center items-center flex-col w-[95%] mx-3`}
background:${palette.primary}
`

export const ContainerMain = styled.div`
${tw`flex justify-center items-center rounded-lg mb-20`}
background:${palette.primary}

`
export const Container4 = styled.div`
${tw`flex justify-center items-center mx-2`}

`
export const ImgOne = styled.img`
${tw`w-[60vw] m-2`}
`
export const ImgTwo = styled.img`
${tw`hidden lg:block w-[30vw] h-[65vh] aspect-[3/2] object-contain  m-2 flex justify-center items-center`}
`

export const MailTag = styled.a`
${tw`text-blue-800`}
`




