import React from 'react';
import SliderImg from '../../images/SliderImg.png';
import {
  SliderContainer,
  MainHeadCont,
  Desc,
  Title,
  MainDesc,
  HeaderImage,
} from '../../styles/CarousalStyle';

import TypewriterComponent from 'typewriter-effect';

const CarouselDefault = () => {
  return (
    <SliderContainer>
      <MainHeadCont>
        <MainDesc>
          <Title>
            <TypewriterComponent
              options={{
                autoStart: true,
                loop: true,
                delay: 40,
                strings: ['Efficiency Empowers Excellence'],
              }}
            />
          </Title>
          <Desc>
            At Leapot Technologies, we specialize in revolutionizing education
            and training with our comprehensive suite of services. From our
            state-of-the-art Learning Management System to our expertly crafted
            Digital Content Development Solutions, we empower educational
            institutions, businesses, and organizations globally to thrive in
            the digital age. With a relentless dedication to innovation and a
            proven history of delivering excellence, we also offer top-tier
            Software Product Development and Staffing Services to ensure our
            clients stay ahead of the curve.
          </Desc>
        </MainDesc>
        <HeaderImage src={SliderImg} />
      </MainHeadCont>

      <svg viewBox="0 0 500 50">
        <path
          d="M 0 25 C 150 75 300 0 500 40 L 500 0 L 0 0"
          fill="#0684fb"
        ></path>
      </svg>
    </SliderContainer>
  );
};

export default CarouselDefault;
