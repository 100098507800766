import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import {
  MainContainer,
  Container1,
  JobcardCont,
  JobCard,
  CardTitle,
  CardDesc,
  // JobLocation,
  Container2,
  Container4,
  Container1B,
  Container1BSub,
  ApplyBtn,
  SearchFilter,
  Container1BSub2,
} from '../../styles/FilterjobStyle';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

const Filterjobs = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJobRole, setSelectedJobRole] = useState(null);
  const [selectedJobLocation, setSelectedJobLocation] = useState(null);
  const [selectedEmploymentTime, setSelectedJobEmploymentTime] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');



  const fetchJobs = async () => {
    try {
      const response = await axios.get(
        'https://leapot-lms-backend.onrender.com/api/getJobData',
      );
      setJobs(response.data.jobs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const jobRoles = Array.from(new Set(jobs.map((job) => job.title)));
  const jobLocations = Array.from(new Set(jobs.map((job) => job.joblocation)));
  const jobEmpTime = Array.from(new Set(jobs.map((job) => job.EmpTime)));

  const jobRoleOptions = jobRoles.map((jobRole) => ({
    value: jobRole,
    label: jobRole,
  }));
  const jobLocationOptions = jobLocations.map((jobLocation) => ({
    value: jobLocation,
    label: jobLocation,
  }));

  const jobEmploymentTimeOptions = jobEmpTime.map((jobEmpTm) => ({
    value: jobEmpTm,
    label: jobEmpTm,
  }));

  const filterJobs = jobs.filter(
    (job) =>
      (!selectedJobRole || job.title === selectedJobRole.value) &&
      (!selectedJobLocation || job.joblocation === selectedJobLocation.value) &&
      (!selectedEmploymentTime ||
        job.EmpTime === selectedEmploymentTime.value) &&
      (searchQuery === '' ||
        job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.description.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Custom styles for the Select components
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid gray',
      borderRadius: '5px',
      minHeight: '40px',
      // maxWidth: '400px',
      width: '85%',
      fontSize: '20px',
      fontWeight: '500',
      margin: '5px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      fontSize: '20px',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: state.isSelected ? '#007bff' : '#f0f0f0',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '20px',
      fontWeight: '500',
    }),
  };

  return (
    <>
      <MainContainer>
        <Container1>
          <Container1B>
            <Grid container spacing={2}>
              {/* Role filter */}
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <Container1BSub>
                  <Select
                    options={jobRoleOptions}
                    placeholder="Role"
                    onChange={(selectedOption) =>
                      setSelectedJobRole(selectedOption)
                    }
                    value={selectedJobRole}
                    isClearable={true}
                    styles={customStyles}
                  />
                </Container1BSub>
              </Grid>

              {/* Location filter */}
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <Container1BSub>
                  <Select
                    options={jobLocationOptions}
                    placeholder="Location"
                    onChange={(selectedOption) =>
                      setSelectedJobLocation(selectedOption)
                    }
                    value={selectedJobLocation}
                    isClearable={true}
                    styles={customStyles}
                  />
                </Container1BSub>
              </Grid>

              {/* Employment time filter */}
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <Container1BSub>
                  <Select
                    options={jobEmploymentTimeOptions}
                    placeholder="Employment type"
                    onChange={(selectedOption) =>
                      setSelectedJobEmploymentTime(selectedOption)
                    }
                    value={selectedEmploymentTime}
                    isClearable={true}
                    styles={customStyles}
                  />
                </Container1BSub>
              </Grid>
              {/* Search input */}
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <Container1BSub2>
                  <SearchFilter
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{fontSize:'20px' , fontWeight:500}}
                  />
                </Container1BSub2>
              </Grid>
            </Grid>
          </Container1B>
          <Grid container spacing={2}>
            {filterJobs.map((job) => (
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                key={job.id}
              >
                <JobcardCont>
                  <JobCard>
                    <Container2>
                      <CardTitle>{job.title}</CardTitle>
                      <CardDesc>{job.description}</CardDesc>
                      <Container4>
                        {/* <CardDesc2>No of Positions: {job.vacancies}</CardDesc2> */}
                        {/* <JobLocation>Location: {job.joblocation}</JobLocation> */}
                      </Container4>
                      {/* <CardDate>{job.date}</CardDate> */}
                      <Link to={`/JobApplication/${job.id}`} state={job}>
                        {/* <Container3> */}
                        <ApplyBtn>Apply</ApplyBtn>
                        {/* </Container3> */}
                      </Link>
                    </Container2>
                  </JobCard>
                </JobcardCont>
              </Grid>
            ))}
          </Grid>
        </Container1>
      </MainContainer>
    </>
  );
};

export default Filterjobs;
