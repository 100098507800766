import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AboutUsPage from './AboutUsPage';
import ProductPage from './ProductPage';
import AcadamyPage from './AcadamyPage';
import CareerPage from './CareerPage';

import Services from '../components/Header/Services';
// import Academy from '../components/Academy';
// import Articles from '../components/Articles';
import Team from '../components/Team';

import Landing from '../components/Landing/Landing';

import ScrollToTop from '../Utilities/ScrollToTop';

import Contactus from '../components/Header/Contactus';
import PrivacyPolicy from '../components/Footer/PrivacyPolicy';
import Terms from '../components/Footer/Terms';
import CSR from '../components/Header/CSR';
import Testimonials from '../components/Header/Testimonials';
import Blogs from '../components/Header/Blogs';
import BlogDetailPage from '../components/BlogDetailPage';
import JobApplication from '../components/JobApplication';

//Being called from App.js for mapping all the routes

function LandingHome() {
  return (
    <>
      {/* To scroll to top of the page  when route changes */}
      <ScrollToTop />
      <Routes>
        {/* Navbar paths before logging In*/}
        <Route path="/" element={<Landing />} />
        <Route path="/About" element={<AboutUsPage />} />
        <Route path="/Product" element={<ProductPage />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Academy" element={<AcadamyPage />} />
        <Route path="/Contactus" element={<Contactus />} />

        {/* <Route path="/Blogs" element={<Blogs />} /> */}
        <Route path="/Team" element={<Team />} />
        <Route path="/Careers" element={<CareerPage />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/BlogDetailPage/:id" element={<BlogDetailPage />} />
        <Route path="/JobApplication/:id" element={<JobApplication />} />

        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/CSR" element={<CSR />} />
        <Route path="/Testimonials" element={<Testimonials />} />
      </Routes>
    </>
  );
}

export default LandingHome;
