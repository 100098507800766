import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  LogoImg,
  NavLink,
  NavMenu,
  NavSecond,
  SideMenu,
  NavMenuB,
  NavMenuBLink,
  NavCont,
  NavMenuCont,
  DropDownCont,
  DropdownArrow,
  UpArrowImg,
  DownArrowImg,
  DropDownContSub,
  DropdownArrow2,
  NavMenuBLink2,
} from '../../styles/LandingStyles.js';

import latestLogoNobg from '../../images/latestLogoNobg.png';
import ham from '../../images/hamburger.png';
import close from '../../images/close.png';

import DropDown from './DropDown';

import up from '../../images/upArrow.png';
import down from '../../images/downArrowG.png';

const NavbarB = ({ userLoggedIn }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false);

  const handleClickB = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  const handleOnLogoClick = () => {
    navigate('/');
  };

  const handleAboutClick = () => {
    setAboutMenuOpen(!aboutMenuOpen);
  };

  const handleNavLinkClick = () => {
    setAboutMenuOpen(false);
  };

  return (
    <>
      <div>
        <NavCont>
          <LogoImg src={latestLogoNobg} alt="" onClick={handleOnLogoClick} />
          <NavSecond onClick={handleClickB}>
            <img style={{ height: '25px', width: '25px' }} alt="" src={ham} />
          </NavSecond>
          <NavMenuCont>
            <NavMenu>
              <NavLink to="/about">About</NavLink>
              <DropDownCont onClick={handleAboutClick}>
                <DropdownArrow style={{}}>
                  {aboutMenuOpen ? (
                    <UpArrowImg src={up} visible={aboutMenuOpen} alt="up" />
                  ) : (
                    <DownArrowImg
                      src={down}
                      visible={!aboutMenuOpen}
                      alt="down"
                    />
                  )}
                </DropdownArrow>
                <DropDownContSub visible={aboutMenuOpen}>
                  {aboutMenuOpen && <DropDown />}
                </DropDownContSub>
              </DropDownCont>
              <NavLink to="/product" onClick={handleNavLinkClick}>
                Products
              </NavLink>
              <NavLink to="/services" onClick={handleNavLinkClick}>
                Services
              </NavLink>
              <NavLink to="/Academy" onClick={handleNavLinkClick}>
                Academy
              </NavLink>

              <NavLink to="/Careers" onClick={handleNavLinkClick}>
                Careers
              </NavLink>
              <NavLink to="/Blogs" onClick={handleNavLinkClick}>
                Blog
              </NavLink>
              <NavLink to="/contactus" onClick={handleNavLinkClick}>
                Contact us
              </NavLink>
            </NavMenu>
          </NavMenuCont>

          <SideMenu open={menuOpen}>
            <NavSecond onClick={handleClickB}>
              <img
                style={{ height: '15px', width: '15px' }}
                alt=""
                src={close}
              />
            </NavSecond>
            {menuOpen && (
              <NavMenuB>
                <NavMenuBLink onClick={handleClickB} to="/">
                  Home
                </NavMenuBLink>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                >
                  <NavMenuBLink onClick={handleAboutClick} to="/about">
                    About
                  </NavMenuBLink>
                  <DropdownArrow2 onClick={handleAboutClick}>
                    {aboutMenuOpen ? (
                      <UpArrowImg src={up} alt="up" />
                    ) : (
                      <DownArrowImg src={down} alt="down" />
                    )}
                  </DropdownArrow2>
                </div>
                {aboutMenuOpen && (
                  <div style={{ marginLeft: '20px', marginTop: '-20px' }}>
                    <NavMenuBLink2 onClick={handleClickB} to="/team">
                      Team
                    </NavMenuBLink2>
                    <NavMenuBLink2 onClick={handleClickB} to="/csr">
                      CSR Activities
                    </NavMenuBLink2>
                  </div>
                )}
                {/* <NavMenuBLink onClick={handleClickB} to="/testimonials">Testimonial</NavMenuBLink> */}
                <NavMenuBLink onClick={handleClickB} to="/product">
                  Product
                </NavMenuBLink>
                <NavMenuBLink onClick={handleClickB} to="/services">
                  Services
                </NavMenuBLink>
                <NavMenuBLink onClick={handleClickB} to="/academy">
                  Academy
                </NavMenuBLink>
                <NavMenuBLink onClick={handleClickB} to="/careers">
                  Careers
                </NavMenuBLink>
                <NavMenuBLink onClick={handleClickB} to="/blogs">
                  Blog
                </NavMenuBLink>
                <NavMenuBLink onClick={handleClickB} to="/contactus">
                  Contact us
                </NavMenuBLink>
              </NavMenuB>
            )}
          </SideMenu>
        </NavCont>
      </div>
    </>
  );
};

export default NavbarB;
