import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  HomeContainer,
  ProductContainer,
  ProductHeadB,
  ProductDesc,
  ProductContSub,
  GridCardContainerSub,
  MainLandingContainerSub,
  ProductHeading,
  ProductPara,
  CardContentCont,
  GridContainer,
  DescCont,
  TitleSub,
  ProductContHeadMain,
} from '../../styles/LandingStyles';

import { Title, TitleDash } from '../../styles/CommonStyles';

import { Grid } from '@mui/material';

import ScrollToTop from '../ScrollToTop';

import Contact from './Contact';

import ServicesLanding from './ServicesLanding';

import CarouselDefault from './CarouselDefault';

const Landing = ({ userLoggedIn, scrollToTop, classes, isVisible }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <CarouselDefault interval={5000} />
      <HomeContainer>
        <ProductHeading>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="150"
          >
            Products
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="200"
          />
        </ProductHeading>
        <MainLandingContainerSub>
          <ProductContSub>
            <ProductHeadB
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="200"
            >
              Unlock Your Potential with Our Innovative Products - Where
              Excellence Meets Affordability!
            </ProductHeadB>
            <ProductDesc
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              Our diverse range of learning products helps you navigate the
              educational landscape effortlessly, from comprehensive learning
              management with Leapot LMS to streamlined event management with
              Leapot EventHub, intuitive event navigation with Leapot
              EventNavigator, and exam integrity assurance with Leapot
              AI-ExamGuard. Explore the possibilities and elevate your learning
              experience with us.
            </ProductDesc>
          </ProductContSub>
          <ProductContHeadMain>
            <ProductContainer>
              <GridCardContainerSub>
                <GridContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                      <CardContentCont
                        data-aos="zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-duration="500"
                        data-aos-delay="200"
                      >
                        <DescCont>
                          <TitleSub>Leapot LMS</TitleSub>
                          <ProductPara>
                            Unlock the Power of Knowledge with Leapot's dynamic
                            Learning Management System! Our cutting-edge LMS
                            offers scalable, feature-packed solutions to enhance
                            training experiences, bridging the gap between
                            virtual and in-person classrooms while streamlining
                            administration, tracking, and reporting.
                          </ProductPara>
                        </DescCont>
                      </CardContentCont>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <CardContentCont
                        data-aos="zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-duration="700"
                        data-aos-delay="300"
                      >
                        <DescCont>
                          <TitleSub>Leapot EventHub</TitleSub>
                          <ProductPara>
                            Elevate your events with Leapot's EventHub software:
                            Simplify, Organize, and Execute seamlessly. Master
                            event planning and execution with our cutting-edge
                            solution designed to streamline every aspect, from
                            scheduling to attendee management. Experience
                            unparalleled ease and efficiency with Leapot's
                            EventHub.
                          </ProductPara>
                        </DescCont>
                      </CardContentCont>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <CardContentCont
                        data-aos="zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-duration="700"
                        data-aos-delay="200"
                      >
                        <DescCont>
                          <TitleSub>Leapot EventNavigator</TitleSub>
                          <ProductPara>
                            Introducing Leapot EventNavigator: Your Passport to
                            Seamless Event Experiences! Navigate effortlessly
                            through a world of upcoming events, detailed
                            schedules, and hassle-free registrations—all in one
                            dynamic platform. With our intuitive calendar
                            interface, registering, paying, and managing your
                            event lineup is a breeze. Join the excitement, mark
                            your calendar, and unlock unforgettable experiences
                            with Leapot EventNavigator today!
                          </ProductPara>
                        </DescCont>
                      </CardContentCont>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <CardContentCont
                        data-aos="zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-duration="700"
                        data-aos-delay="300"
                      >
                        <DescCont>
                          <TitleSub>Leapot AI-ExamGuard</TitleSub>
                          <ProductPara>
                            Leapot Technologies introduces Leapot AI-ExamGuard,
                            an AI-driven assessment platform facilitating
                            seamless creation and administration of diverse
                            quizzes and exams. With advanced proctoring features
                            ensuring exam integrity, educators can effortlessly
                            assign grades and track learner progress via Leapot
                            LMS. Offering personalized learning experiences,
                            real-time tracking, and analysis, Leapot
                            AI-ExamGuard revolutionizes education by enriching
                            the learning process and enhancing assessment
                            integrity.
                          </ProductPara>
                        </DescCont>
                      </CardContentCont>
                    </Grid>
                  </Grid>
                </GridContainer>
              </GridCardContainerSub>
            </ProductContainer>
          </ProductContHeadMain>
        </MainLandingContainerSub>
        <ServicesLanding />
        <Contact />
        <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        />
      </HomeContainer>
    </>
  );
};

export default Landing;
