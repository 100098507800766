import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';



export const FooterContainer = styled.div`
  ${tw`relative bottom-0 mt-20 w-full flex justify-center items-center flex-col sm:flex-row sm:h-24`};
  background-color: ${palette.secondary};
  letter-spacing: 0.015em;
  column-gap: 28px;
`;

export const Container = styled.div`
  ${tw`flex justify-center items-center`}
  letter-spacing: 0.015em;
  column-gap: 4px;
`;

export const Text = styled.p`
  ${tw`2xl:text-xl xl:text-xl lg:text-xl md:text-sm text-cyan-50`}
`;

export const LinkToFreepik = styled.a`
  ${tw `text-white`}
`;

export const FooterText = styled.p`
  ${tw`2xl:text-xl xl:text-base lg:text-xs  md:text-xs cursor-pointer underline text-cyan-50 `}
`;


export const FooterText2 = styled.p`
  ${tw`2xl:text-xl xl:text-base lg:text-xs  md:text-xs cursor-pointer no-underline text-cyan-50 `}

`;
