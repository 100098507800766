import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function MediaCard() {
  const [blogs, setBlogs] = useState([]);


  useEffect(()=>{
    axios.get('https://leapot-lms-backend.onrender.com/api/getBlogData')
    .then(response =>{
      setBlogs(response.data.blogs)
    })
    .catch(error =>{
      console.log('Error in fetching data:', error)
    });
  }, [])

  return (
    <Grid container spacing={2}>
      {blogs.map((blog, index) => (
        <Grid
          style={{
            marginBottom: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          key={index}
        >
          <Card
            sx={{
              maxWidth: 500,
              minHeight: 450,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <CardMedia
              sx={{ height: 180, aspectRatio: 3 / 2 }}
              image={blog.image}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {blog.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {blog.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Link
                to={{ pathname: `/BlogDetailPage/${blog.id}` }}
                style={{ textDecoration: 'none' }}
              >
                <Button size="small">Learn More</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
