import React from 'react'
import Careers from '../components/Header/Careers'

export default function CareerPage() {
  return (
    <>
      <h1>
      <Careers/>
      </h1>
    </>
  )
}
