import React, { useState } from 'react';
import { serviceDropDown } from '../../landingData/NavItems';
import { TabCont, TabContSub, LinkTag2 } from '../../styles/DropDownStyle';

const DropDown = ({ handleLinkTag2Click }) => {
  const [dropDown, setDropDown] = useState(false);

  return (
    <>
      <TabCont onClick={() => setDropDown(!dropDown)}>
        {serviceDropDown.map((item) => {
          return (
            <TabContSub key={item.id}>
              <LinkTag2 to={item.path} onClick={handleLinkTag2Click}>
                {item.title}
              </LinkTag2>
            </TabContSub>
          );
        })}
      </TabCont>
    </>
  );
};

export default DropDown;
