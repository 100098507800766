import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";


export const MainContainer1 = styled.div`
${tw`mt-40 mx-20`}
`

export const MainContainer2 = styled.div`
${tw`mt-28 mx-20`}
`

export const Container1 = styled.div`
${tw`flex justify-center items-center flex-col my-10`}
`

export const Container2 = styled.div`
${tw`my-20 `}
`

export const Container2Main = styled.div`
${tw`my-20 mt-[-170px] sm:mt-[-150px] md:mt-[0]`}
`

export const Container3 = styled.div`
${tw`my-20`}
`

export const Container3A = styled.div`
${tw`flex justify-center items-center flex-col md:flex-row my-5`}
// border:2px solid red;
`


export const Container3B = styled.div`
${tw`my-10 m-2`}
`



export const Title = styled.h1`
  ${tw` font-bold text-lg mb-10 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-xl sm:text-lg leading-4 `}
  color: ${palette.primary};
`;


export const TitleB = styled.h1`
  ${tw` font-bold text-lg 2xl:text-3xl xl:text-3xl lg:text-3xl md:text-xl sm:text-lg leading-4 `}
  color: ${palette.primary};
`;

export const TitleC = styled.h1`
${tw` font-bold text-lg 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-lg sm:text-base leading-4 `}
color: ${palette.primary};
`


export const ImgCont = styled.img`
${tw`h-[70vh] w-[85vw] mt-[-100px] md:mt-[0] rounded-xl object-contain`}
`

export const ImgContSub = styled.img`
${tw` w-[50vw]  md:h-[30vh] md:w-[20vw] rounded-xl object-contain aspect-[3/2] m-2`}
// border:2px solid red;
`

export const Paragraph = styled.p`
${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8 
lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
color: ${palette.black};

`
export const ParagraphB = styled.p`
${tw`font-thin 2xl:text-lg xl:text-lg xl:leading-8 text-justify leading-8 
lg:text-base lg:leading-8 md:text-base  md:leading-7 sm:text-base tracking-wide`}
color: ${palette.black};
`

