import React from 'react';
import { useParams } from 'react-router-dom';

import {
  MainContainer1,
  MainContainer2,
  Container1,
  Container2,
  Container3,
  Container3A,
  Container3B,
  Title,
  TitleB,
  TitleC,
  ImgCont,
  ImgContSub,
  Paragraph,
  ParagraphB,
  Container2Main,
} from '../styles/BlogDetailPageStyle';

import img1 from '../images/adaptive_e-learning.jpg';
import img2 from '../images/main_adaptive.jpg';
import img3 from '../images/adaptive_paths.jpg';
import img4 from '../images/adaptive_feedback.jpg';
import img5 from '../images/adaptive_data.jpg';
import img6 from '../images/adaptive_interaction.jpg';

import I1 from '../images/Headless_lms.jpg';
import I2 from '../images/customize_headless.png';
import I3 from '../images/scalable_headless.png';
import I4 from '../images/responsive-design_lms.png';
import I5 from '../images/cost_headless.png';

const BlogDetailPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === '2' ? (
        <MainContainer1>
          <Container1>
            <Title>Adaptive e-learning systems</Title>
            <ImgCont src={img1} />
          </Container1>
          <Container2>
            <TitleB>What are Adaptive e-learning systems?</TitleB>
            <Paragraph>
              Adaptive e-learning systems are recognized as one of the most
              captivating subjects in remote web-based education due to the
              proliferation of information and communication technology across
              various industries, which has also influenced the educational
              sector. Adaptive e-learning systems are technological platforms or
              tools designed to provide personalized learning experiences to
              individual learners. As the term 'adaptive' implies, it is a
              process in which lessons or content are tailored or taught based
              on the learner's performance. These systems operate on the
              principle that each student is unique, with different educational
              backgrounds, learning styles, and educational needs. The goal of
              these systems is to offer more personalized and effective learning
              experiences, addressing the distinct needs and abilities of
              individual learners and fostering greater engagement, motivation,
              and learning outcomes.
              <br />
              <br />
              These systems customize the learning process to fit the specific
              requirements, interests, and skills of each student through the
              utilization of algorithms and data analysis. The objective is to
              create an optimal learning environment that enhances
              understanding, retention, and engagement. These systems gather
              learner data through algorithms and data analytics, including
              information on learning styles, progress, strengths, and
              weaknesses. Based on this data, the content, pace, and method of
              distributing learning materials are continuously adjusted.
            </Paragraph>
          </Container2>
          <Container3>
            <TitleB>How can an adaptive e-learning system help you?</TitleB>
            {/* <Container3Sub> */}
            <Container3A>
              <ImgContSub src={img2} />
              <Container3B>
                <TitleC>Personalized Content</TitleC>
                <ParagraphB>
                  First, information on the student's performance, educational
                  background, objectives, areas of strength and weakness, and
                  preferred learning method must be gathered. This information
                  is useful for the instructor in modifying the study material's
                  content based on the student's level of learning competence.
                  Forums for quizzes are created to help learners improve their
                  critical thinking skills on various tests. This may entail
                  adjusting the degree of difficulty, giving more details or
                  illustrations, or supplying substitute materials.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={img3} />
              <Container3B>
                <TitleC>Individualised Learning Paths</TitleC>
                <ParagraphB>
                  These provide each student with a unique learning experience
                  by customizing study materials, videos, examinations, quizzes,
                  and assignments to match their goals, areas of interest, level
                  of competency in thinking, and prior knowledge.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={img4} />
              <Container3B>
                <TitleC>Real-time Feedback</TitleC>
                <ParagraphB>
                  As students move through the course material, adaptive
                  e-learning technologies provide them with rapid, focused
                  feedback. This feedback points out areas where learners need
                  to concentrate their efforts and helps them understand their
                  strengths and limitations. This feedback helps learners trace
                  their learning journey and discover areas for development. It
                  can take many different forms, such as automatic assessments,
                  quizzes, or tailored recommendations for more study.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={img5} />
              <Container3B>
                <TitleC>Data-driven Adaptation</TitleC>
                <ParagraphB>
                  These systems assess student performance, engagement, and
                  learning outcomes using data analytics and machine learning
                  algorithms. Continuously tracking and evaluating performance
                  makes it simple to modify the study guide or add more
                  information for quizzes, tutorials, or assignments.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={img6} />
              <Container3B>
                <TitleC>Interaction and Involvement</TitleC>
                <ParagraphB>
                  Adaptive e-learning systems frequently include interactive
                  components like games, simulations, and multimedia content to
                  increase student engagement. In addition to making learning
                  more interesting, these interactive elements offer chances for
                  active learning and practical application through simulation.
                  The preferences and learning style of the student may be
                  catered for in these interactive elements.
                </ParagraphB>
              </Container3B>
            </Container3A>
          </Container3>
        </MainContainer1>
      ) : id === '1' ? (
        <MainContainer2>
          <Container1>
            <Title>Introduction to Headless LMS</Title>
            <ImgCont src={I1} />
          </Container1>
          <Container2Main>
            <TitleB>Definition: What is Headless LMS?</TitleB>
            <Paragraph>
              It also brings a demand for memorable digital experiences, the
              emergence of eLearning content available on demand. Designing and
              delivering a highly circumstantial, dynamic, and personalized or
              customized experience is an exciting potential for customer
              academic organizations and learning enterprises, without staying
              inside the strict confines of a learning management system (LMS).
              <br />
              <br />
              As implied by the term "headless," headless LMS lack a
              pre-established user interface. A headless LMS has the option to
              replace a system's default learner interface with one that a
              client has designed, modified, and kept up to date. In traditional
              LMS, the frontend, or user interface, and the backend, or
              database, logic, and server, are closely interwoven in a standard
              LMS, resulting in a coherent but frequently rigid structure.
              However, because Headless LMS isolates the front-end presentation
              layer from the back-end services, it is advantageous to the
              education sector.
            </Paragraph>
          </Container2Main>
          <Container2>
            <TitleB>Why go with headless LMS?</TitleB>
            <Paragraph>
              Headless LMS has several advantages for the learning or education
              industry. The user interface is the primary issue that all
              learning platforms deal with. The new bespoke front end that
              interacts with the LMS back-end database using the user's current
              API to drag data for display and push data to the back-end for
              storage is an alternative to the headless LMS.
              <br />
              <br />
              Delivering information across several channels is a big benefit of
              employing an LMS. Delivering material across many channels, like
              as websites, mobile applications, and other digital platforms, is
              made possible by headless LMS. This adaptability facilitates the
              incorporation of cutting-edge technology into various hardware and
              user interfaces.
            </Paragraph>
          </Container2>
          <Container2>
            <TitleB>How does headless LMS work in practice?</TitleB>
            <Paragraph>
              In practice, a headless Learning Management System (LMS) operates
              by decoupling the back-end functionality (where data and logic
              reside) from the front-end user interface. Here's how it works:
              <br />
              <br />
              Back-end Functionality: the back-end of a headless LMS contains
              all the essential features, This includes databases, servers,
              APIs, and business logic necessary for handling data and
              processing requests.
              <br />
              <br />
              APIs (Application Programming Interfaces): These APIs typically
              use standard protocols such as RESTful APIs or GraphQL to
              facilitate communication between the back-end and front-end
              components.
              <br />
              <br />
              Front-end Interfaces: Unlike traditional monolithic LMS, which
              comes with a built-in user interface, headless LMS does not
              dictate the presentation layer. Instead, organizations have the
              flexibility to develop custom front-end interfaces using their
              preferred technologies and frameworks.
              <br />
              <br />
              Integration: Custom front-end interfaces communicate with the
              headless LMS back-end through APIs, fetching data and sending
              requests as needed. Integration enables a unified learning
              ecosystem where data flows freely between different systems,
              enhancing efficiency and user experience.
            </Paragraph>
          </Container2>
          <Container3>
            <TitleB>Why is headless LMS superior to conventional LMS? </TitleB>
            <Container3A>
              <ImgContSub src={I2} />
              <Container3B>
                <TitleC>Flexibility and customization</TitleC>
                <ParagraphB>
                  Customizing the user interface is the challenge faced by the
                  learning industry. In traditional LMS, the design of the
                  system may impose limitations on adding new features or
                  customizing the user interface, which frequently calls for
                  substantial development work. Whereas, Headless LMS allows for
                  greater customization because developers can build custom
                  front-end interfaces tailored to specific needs without
                  affecting the underlying functionality of the LMS.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={I3} />
              <Container3B>
                <TitleC>Scalability</TitleC>
                <ParagraphB>
                  Decoupling the front end from the back end allows for greater
                  scalability. When referring to conventional LMS, it can be
                  difficult and resource-intensive to scale the LMS,
                  particularly when there are more users or changing technology
                  needs. Each module functions independently of the others,
                  adding new features may be done without interfering with the
                  system as a whole, which can be effectively achieved by
                  headless LMS.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={I4} />
              <Container3B>
                <TitleC>Responsive design</TitleC>
                <ParagraphB>
                  Responsive design further facilitates multi-channel content
                  distribution. A headless LMS's ability to adjust content to
                  different screen sizes and resolutions makes using it on
                  computers, tablets, and mobile devices effortless.
                </ParagraphB>
              </Container3B>
            </Container3A>
            <Container3A>
              <ImgContSub src={I5} />
              <Container3B>
                <TitleC>Cost-Efficiency</TitleC>
                <ParagraphB>
                  While initial setup costs may be higher compared to
                  traditional LMS, headless LMS offers long-term cost savings
                  due to its scalability, flexibility, and ability to integrate
                  with existing systems. Organisations can avoid vendor lock-in
                  and reduce maintenance overhead by choosing a headless
                  approach.
                </ParagraphB>
              </Container3B>
            </Container3A>
          </Container3>
        </MainContainer2>
      ) : (
        <div>No data available</div>
      )}
    </>
  );
};

export default BlogDetailPage;
