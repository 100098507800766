import { Routes, Route, useLocation } from 'react-router-dom';
// import Landing from "./components/Landing/Landing";
// import About from "./components/Landing/About";
// import Services from "./components/Landing/Services";

// import Articles from "./components/Landing/Articles";
import Testimonials from './components/Header/Testimonials';
// import Team from "./components/Landing/Team";
// import Contact from "./components/Landing/Contact";
// import Navbar from "./components/Landing/Navbar";
import Footer from './components/Footer/Footer';
// import LandingHome from "./components/LandingHome";
import NavbarB from './components/Header/NavbarB';
import HomePage from './pages/HomePage';
function App() {
  const location = useLocation();

  const hideNav = location.pathname === '/';
  return (
    <div style={{ minHeight: '100vh' }}>
      {' '}
      <NavbarB />
      {/* <LandingHome/> */}
      <HomePage />
      <Footer />
      {/* <Navbar /> */}
      <Routes>
        {/* Navbar paths before logging In*/}
        {/* <Route path="/" element={<Landing />} /> */}
        {/* <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Academy" element={<Academy />} />
        
        <Route path="/Articles" element={<Articles />} />
        
        <Route path="/Team" element={<Team />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Navbar" element={<Navbar />} />
ab        <Route path="/LandingHome" element={<LandingHome />} /> */}
        {/* <Route path="/Testimonials" element={<Testimonials />} /> */}
      </Routes>
    </div>
  );
}

export default App;
