import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../styles/palette';
import { color } from 'framer-motion';

export const AcademyContainer = styled.div`
  ${tw`flex justify-center items-center flex-col p-24 mt-10
`}
`;


export const TitleHead = styled.div`
  ${tw`flex justify-center items-center flex-col mt-10`}
`;

export const ExploreBtn = styled.p`
  ${tw`fixed bottom-3`}
  color:${palette.primary}
`;


export const MainContainer = styled.div`
  ${tw`flex justify-center items-center mb-10 flex-col mt-10 mx-10
  xl:flex-row
  lg:flex-row
  md:flex-col
`}
`;

// export const AcademyImg = styled.img`
//   ${tw`w-[430px]  h-[410px] px-14 
//   sm:w-[300px] sm:h-[300px]
//   md:w-[520px] md:h-[520px] md:mt-5
//   lg:w-[400px] lg:h-[430px]
//   xl:w-[800px] xl:h-[800px]
//   `}
// `;


export const AcademyImg = styled.img`
  ${tw`w-[60vw] h-auto mx-2
    md:w-[70%]
    lg:w-[50%]
  `}
`;

export const SubContainer1 = styled.div`
  ${tw`flex justify-center flex-col items-center m-10 mx-[-40px] max-w-[600px] 
  sm:mx-[0px]
xl:justify-start xl:items-start 
lg:justify-center lg:items-center lg:w-[500px]
md:justify-center md:items-center md:w-[600px]
`}
`;


export const MainParagraph = styled.p`
  ${tw` flex justify-center items-center w-[100%] leading-relaxed font-bold text-lg
  md:text-xl
  lg:text-xl
  xl:text-2xl
`}
`;

export const Paragraph = styled.p`
  ${tw` flex justify-center text-justify items-center w-[100%] leading-relaxed
sm:text-base
md:text-base
lg:text-lg
xl:text-lg
`}
`;

export const SubContainer2 = styled.div`
  ${tw`w-screen flex justify-center items-center flex-col`}
  background-color: ${palette.secondary};
`;

export const GridCardContainerSub = styled.div`
  ${tw`mt-[-22px] `}
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
`;

export const GridHeading = styled.h1`
  ${tw`flex justify-center items-center text-2xl m-10 `}
  color: ${palette.white};
`;

export const GridSubHeading = styled.p`
  ${tw`flex justify-center items-center m-10  mt-[-10px] text-lg font-bold leading-relaxed`}
  color: ${palette.white};
`;

export const GridCardContainer = styled.div`
  ${tw`m-5`}
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two cards per row by default */
  gap: 20px;
  max-width: 1270px; /* Set a max-width for the grid */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One card per row for smaller screens */
  }
`;

export const SubContainer3 = styled.div`
  ${tw`mt-10 mx-[-50px] `}
`;

export const Heading = styled.h1`
  ${tw`flex justify-center items-center text-3xl mt-10 
`}
  color:${palette.primary}
`;

export const SpanStyle = styled.span`
  ${tw``}
  color:${palette.primary}
`;

export const InnerContainer = styled.div`
  ${tw`flex justify-center items-center flex-col w-full h-full py-3 leading-relaxed  
  lg:flex-row
  `}
`;

export const InnerSubContainer = styled.div`
  ${tw`flex justify-center items-center flex-col px-6 w-full h-full`}/* display: flex;
  justify-content: center;
  flex-direction: row; */
`;

export const Header = styled.h1`
  ${tw`flex justify-center items-center text-lg  font-bold
  `}
`;

export const Description = styled.p`
  ${tw`flex justify-center text-justify items-center text-lg leading-relaxed mt-1
  `}
`;

export const SubContainer4 = styled.div`
  ${tw`flex justify-center items-center flex-col w-screen mt-16 mb-[-50px]
  lg:flex-row
  `}
  background-color: ${palette.secondary};
  height: 100%;
`;

export const AcademyImage = styled.img`
  ${tw`hidden lg:block h-[290px] w-[480px]  px-10 mt-20 mb-[-62px]
  // lg:h-[300px] w-[650px]
  // xl:h-[290px] w-[480px]
  // md:h-[250px] md:w-[300px]
  `}
`;


export const AcademyImage2 = styled.img`
  ${tw`lg:hidden h-[290px] w-[320px] mt-10 mb-[-62px]
  sm:w-[480px] 
  // lg:h-[300px] w-[650px]
  // xl:h-[290px] w-[480px]
  // md:h-[250px] md:w-[300px]
  `}
`;

export const InnerContainer2 = styled.div`
${tw`flex justify-center items-center flex-col`}
`;

export const Container4Heading = styled.h1`
  ${tw`flex justify-center items-center text-3xl m-10
  `}
  color: ${palette.white}
`;

export const Container4Paragraph = styled.p`
  ${tw`flex justify-center text-center items-center mx-2 text-lg font-thin leading-relaxed`}
  color: ${palette.white}
`;

export const NavLink = styled.a`
  ${tw`flex justify-center items-center text-white mx-16`}
  border: none;
  text-decoration:none;
`;

export const SubContainer5 = styled.div`
  ${tw`mt-44 mb-[-170px] flex justify-center flex-col items-center`}
`;

export const Container5Heading = styled.h1`
  ${tw`flex justify-center text-center items-center text-xl font-medium
  md:text-3xl
   `}
  color:${palette.primary}
`;


export const InnerContainer5 = styled.div`
  ${tw` flex justify-center items-center flex-col w-[100%] h-full
  lg:flex-row
  `}
`;

export const InnerSubContainer5 = styled.div`
  ${tw`flex justify-center items-center flex-col w-[100%]`}
`;

export const Container5Header = styled.h1`
  ${tw`flex justify-center items-center text-lg font-bold leading-relaxed
  `}
`;

export const Container5Description = styled.p`
  ${tw`flex justify-center items-center text-lg w-[200px] 
  `}
`;

export const Container5Image = styled.img`
  ${tw`flex justify-center object-contain items-center mix-blend-color-burn h-24 w-24 p-4 mx-3 `}
  aspect-ratio: 3/2;
`;

export const AcademyButton = styled.button`
  ${tw`flex justify-center cursor-pointer items-center p-2 rounded-md  px-5 mt-4 mx-3`}
  border-width: 0;
  background:${palette.primary}
`;

export const AnchorTag = styled.a`
${tw` no-underline`}
`

export const AcademyCardStyle = {
  minWidth: 250,
  margin: '10px',
  height: '260px',
  boxShadow: '0px 0px 10px 0px black',
  '@media (min-width: 608px) ': {
    height: '200px',
  },
  '@media (min-width: 768px) ': {
    height: '180px',
  },
  '@media (min-width: 900px) ': {
    height: '300px',
  },
  '@media (min-width: 1024px) ': {
    height: '260px',
  },
  '@media (min-width: 1536px) ': {
    height: '220px',
  },
};
