import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  ContactContMain,
  ContactFormCont,
  TextFieldStyle,
  Desc,
  ContactFormMain,
  ContactImg,
  SubmitButton,
  ContactFormCont2,
  ContactFormMainsub,
  Title2,
  Desc2,
  ContSub2,
  ContSub2Main,
  ContactFormCont3,
  TitleCont,
  TitleLogo,
  TitleDash2,
  TitleContB,
  ContSub2B,
  ContSub2MainB,
  PhoneInputContainer,
} from '../../styles/ContactusStyle';
import MinHeightTextarea from './MinHeightTextarea';
import { Title, TitleDash } from '../../styles/CommonStyles';
import TextField from '@mui/material/TextField';
import ContactUsImg from '../../images/Contactus.jpg';
import linkedin from '../../images/linkedin.png';
import ph from '../../images/telephone.png';
import Em from '../../images/mail.png';
import Ad from '../../images/location.png';

import ScrollToTop from '../ScrollToTop';
import axios from 'axios';

const Contactus = (scrollToTop, classes, isVisible) => {
  const [loading, setLoading] = useState(false);

  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [state, setState] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === '') {
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'email' && !validateEmail(value)) {
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'name' && !validateName(value)) {
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'mobile' && value.replace(/\D/g, '').length === 0) {
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newErrorState = {
      name: state.name === '',
      email: state.email === '' || !validateEmail(state.email),
      mobile:
        state.mobile === '' || state.mobile.replace(/\D/g, '').length === 0,
      message: state.message === '',
    };

    setErrorState(newErrorState);

    if (Object.values(newErrorState).some((field) => field)) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://leapot-lms-backend.onrender.com/api/addContactUs',
        {
          name: state.name,
          email: state.email,
          mobile: state.mobile,
          // subject: state.subject,
          message: state.message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data && response.data.message === "Contact form submitted successfully") {
        alert('Your details have been forwarded to the concerned team.');
        setErrorState({
          name: false,
          email: false,
          mobile: false,
          // subject: false,
          message: false,
        });
        setState({
          name: '',
          email: '',
          mobile: '',
          // subject: '',
          message: '',
        });
      } else {
        alert('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <ContactContMain>
      <TitleContB>
        <Title>Contact us</Title>
        <TitleDash />
      </TitleContB>
      <Desc>
        Got questions or inquiries? We're here to help! Contact our friendly
        team at Leapot Technologies for expert assistance, partnership
        opportunities, or to learn more about our services. Your success is our
        priority, and we're ready to support you on your journey. Reach out to
        us today and let's start a conversation!
      </Desc>
      <ContactFormMain>
        <ContactFormMainsub>
          <ContactImg src={ContactUsImg} />
          <ContactFormCont>
            <TextField
              required
              label="Enter Name"
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder="Name"
              sx={TextFieldStyle}
              error={errorState.name}
              value={state.name}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, name: e.target.value })}
              helperText={
                errorState.name
                  ? 'Please enter alphabets only in the name field.'
                  : ''
              }
            />
            <TextField
              required
              label="Enter Email"
              type="text"
              name="email"
              id="email"
              variant="outlined"
              placeholder="Email"
              sx={TextFieldStyle}
              error={errorState.email}
              value={state.email}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              helperText={
                errorState.email ? 'Please enter a valid email address.' : ''
              }
            />
            <PhoneInputContainer error={errorState.mobile}>
              <PhoneInput
                country={'in'}
                value={state.mobile}
                onChange={(mobile) => setState({ ...state, mobile })}
                countryCodeEditable={false}
                onBlur={handleBlur}
              />
              {errorState.mobile && (
                <span style={{ color: 'red', fontSize: '0.75rem' }}>
                  Please enter a mobile number.
                </span>
              )}
            </PhoneInputContainer>

            <MinHeightTextarea
              error={errorState.message}
              value={state.message}
              onChange={(newValue) => setState({ ...state, message: newValue })}
              helperText={errorState.message ? 'Please enter a message.' : ''}
            />
            <SubmitButton onClick={handleSubmit} disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </ContactFormCont>
        </ContactFormMainsub>
      </ContactFormMain>
      <ContactFormCont2>
        <Title2>Connect with us</Title2>
        <TitleDash2 />
        <ContactFormCont3>
          <ContSub2Main>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={ph} />
              </TitleCont>
              <Desc2>+917038585222</Desc2>
            </ContSub2>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={Em} />
              </TitleCont>
              <Desc2>contact@leapot.in</Desc2>
            </ContSub2>
          </ContSub2Main>
          <ContSub2MainB>
            <ContSub2>
              <TitleCont>
                <a
                  href="https://in.linkedin.com/company/leapot-technologies"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TitleLogo src={linkedin} />
                </a>
              </TitleCont>
              <Desc2>linkedIn</Desc2>
            </ContSub2>
            <ContSub2B>
              <TitleCont>
                <a
                  href="https://www.google.com/maps/place/Gera's+Imperium+Gateway/@18.6100983,73.8200787,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2b9b5631828cb:0x6370d884949e3645!8m2!3d18.6100983!4d73.8200787!16s%2Fg%2F11lkzqzbf8?authuser=0&entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TitleLogo src={Ad} />
                </a>
              </TitleCont>
              <Desc2>Location</Desc2>
            </ContSub2B>
          </ContSub2MainB>
        </ContactFormCont3>
      </ContactFormCont2>
      <ScrollToTop
        isVisible={isVisible}
        scrollToTop={scrollToTop}
        classes={classes}
      />
    </ContactContMain>
  );
};

export default Contactus;
