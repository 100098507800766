import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";

export const MainContainer = styled.div`
${tw`mt-20 mx-0 lg:mx-0 xl:mx-40 `}
`;

export const Container1 = styled.div`
${tw`flex justify-center items-center flex-col`}
`;

export const Container1B = styled.div`
${tw`flex justify-center items-center mr-[-45px] w-[90vw]`}
`;

export const Container1BSub = styled.div`
${tw``}

`;

export const Container1BSub2 = styled.div`
${tw`flex justify-center items-center ml-[5px] w-[86%] `}
`;

export const SearchFilter = styled.input`
${tw`mt-1 h-[34px] w-[100%]`}
fontSize: '20px',
fontWeight: '500',
`;


export const JobcardCont = styled.div`
${tw`mt-20 flex justify-center mx-2 py-5 items-center w-[100%]`}
border-bottom: 2px solid #036ACB;
`;

export const JobCard = styled.div`
${tw`flex justify-center items-center`}
`;

export const Container2 = styled.div`
${tw`flex justify-center items-start flex-col`}
`;
export const Container3 = styled.div`
${tw`flex justify-center items-center mt-5 `}
`;

export const Container4 = styled.div`
${tw`flex justify-center items-center`}
`;

export const CardTitle = styled.h1`
${tw`text-xl md:text-2xl`}
`;

export const CardDesc = styled.p`
${tw`text-base md:text-lg lg:text-xl mt-[-5px] font-medium  text-justify `}
`;

export const CardDesc2 = styled.p`
${tw`text-xl mt-[-5px]`}
`;

export const CardDate = styled.p`
${tw`text-xl  font-medium mt-[-5px] `}
`;

export const JobLocation = styled.p`
${tw`flex justify-center items-center mx-5 mt-[-5px] text-xl `}
`;

export const ApplyBtn = styled.button`
${tw`p-2 w-[150px] md:p-3 md:w-[200px] text-white font-medium cursor-pointer rounded-xl`}
border: none; 
background-color:${palette.primary}
`;