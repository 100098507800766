import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  MainServiceCont,
  ServiceDescCont,
  ServiceHeadSub,
  ServiceDesc,
  ServiceCardsCont,
  SpanTag,
  TextContOne,
  TextContTwo,
  TextContThree,
  TextContFour,
  HeadOne,
  HeadTwo,
  HeadThree,
  HeadFour,
  DescOne,
  DescTwo,
  DescThree,
  DescFour,
  SectionA,
  SectionB,
} from '../../styles/ServicesLandingStyle';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

const ServicesLanding = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <MainServiceCont>
        <TitleHeadComCont>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Services
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          />
        </TitleHeadComCont>
        <ServiceDescCont>
          <ServiceHeadSub
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            Empowering Your Success, One Service at a Time!
          </ServiceHeadSub>
          <ServiceDesc
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="300"
          >
            Explore a realm of personalized services at Leapot Technologies,
            where innovation meets your unique needs. From{' '}
            <SpanTag>
              {''} Custom Software Development{''}
            </SpanTag>{' '}
            that transforms your vision into reality, to cutting-edge{' '}
            <SpanTag>
              {''}Digital Learning Solutions{''}
            </SpanTag>{' '}
            empowering individuals and organizations. Streamline your workforce
            with our specialized
            <SpanTag> Staffing Services{''}</SpanTag> or enhance your
            educational initiatives with our array of supplementary services.
            With Leapot, revolutionize your operations, elevate your learning
            experiences, and achieve unparalleled success today!
          </ServiceDesc>
        </ServiceDescCont>

        <ServiceCardsCont>
          <SectionA>
            <TextContOne
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <HeadOne>Custom Software Development</HeadOne>
              <DescOne>
                At Leapot, we specialize in transforming your vision into
                tailor-made software solutions, ensuring innovation,
                scalability, and superior quality to meet your unique demands.
                Unlike off-the-shelf software, our custom solutions are built
                from scratch, fully customizable to enhance efficiency and
                productivity, catering to businesses of all sizes. Take the leap
                and revolutionize your operations with our custom software
                development services today!
              </DescOne>
            </TextContOne>
            <TextContTwo
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <HeadTwo>Digital Learning Solutions</HeadTwo>
              <DescTwo>
                Empower individuals and organizations alike with our
                cutting-edge Digital Learning Solutions, offering dynamic
                multimedia content and interactive resources tailored to diverse
                learning needs. Join us in shaping the future of education
                through immersive and engaging digital experiences.
              </DescTwo>
            </TextContTwo>
          </SectionA>
          <SectionB>
            <TextContThree
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <HeadThree>Other Educational Services</HeadThree>
              <DescThree>
                Explore our array of supplementary educational services tailored
                to enrich teaching and learning experiences. From curriculum
                development and learning management system integration to
                professional development workshops and educational consulting,
                we offer comprehensive support to educators, institutions, and
                learners alike. Elevate your educational initiatives with our
                expertise and innovative solutions.
              </DescThree>
            </TextContThree>
            <TextContFour
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <HeadFour>Staffing Services</HeadFour>
              <DescFour>
                Looking to streamline your workforce with top talent in today's
                competitive landscape? Look no further! At Leapot Technologies
                we specialize in providing staffing solutions tailored to your
                specific needs. Whether you're seeking seasoned software
                engineers, creative graphic designers, or efficient virtual
                assistants, we've got you covered. Our extensive network of
                skilled professionals spans across various industries, ensuring
                that you find the perfect fit for your remote team. With our
                commitment to excellence and personalized service, let us help
                you build the dream team you've been searching for. Partner with
                us today and elevate your business to new heights!
              </DescFour>
            </TextContFour>
          </SectionB>
        </ServiceCardsCont>

        <TitleHeadComCont>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Leapot Academy
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          />
        </TitleHeadComCont>
        <ServiceDesc
        // data-aos="fade-left"
        // data-aos-easing="ease-in-out"
        // data-aos-duration="500"
        // data-aos-delay="100"
        >
          Welcome to Leapot Academy, your gateway to unlocking boundless
          opportunities for personal and professional growth. Dive into our
          curated collection of online courses designed to cater to both
          freshers and experienced professionals, empowering learners to thrive
          in today's dynamic landscape.
        </ServiceDesc>
      </MainServiceCont>
    </>
  );
};

export default ServicesLanding;
