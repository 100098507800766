// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import {
//   MainContainer,
//   Container2,
//   FieldLabel,
//   FieldLabel2,
//   FiledCont,
//   Container1,
//   Heading,
//   Heading2,
//   Heading3,
//   Heading4,
//   Heading5,
//   Container2A,
//   Container2B,
//   Submitbtn,
//   Container2AB,
//   Container2C,
//   InputFiledStyle,
//   UploadFileBtn,
//   RemoveButton,
//   RemoveButtonCont,
//   Container2CMain,
//   Container2CB,
// } from '../styles/JobApplicationStyle';

// import axios from 'axios';

// import { TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';

// import Grid from '@mui/material/Grid';

// const JobApplication = (props) => {
//   const { id } = useParams();
//   const [jobData, setJobData] = useState(null);
//   const navigate = useNavigate();

//   const fileInputRef = useRef();

//   const handleRemoveFile = () => {
//     fileInputRef.current.value = '';
//     setFile(null);
//     setFileName('');
//   };

//   useEffect(() => {
//     const fetchJobData = async () => {
//       try {
//         const response = await axios.get(
//           // `https://leapot-lms-backend.onrender.com/api/getJobData/${id}`,
//           `http://localhost:8000/api/getJobData/${id}`,
//         );
//         setJobData(response.data.job);
//       } catch (error) {
//         console.error('Error fetching job data:', error);
//       }
//     };

//     fetchJobData();
//   }, [id]);

//   const [formData, setFormData] = useState({
//     title: jobData && jobData.title ? jobData.title : 'Default Title',
//     name: '',
//     dob: '',
//     nationality: '',
//     contactno: '',
//     email: '',
//     collegename: '',
//     yearofpassing: '',
//     percent: '',
//     withoutpay: '',
//     Employmenttype: '',
//     resumeFile: null,
//   });

//   const [errorState, setErrorState] = useState({
//     name: false,
//     dob: false,
//     nationality: false,
//     contactno: false,
//     email: false,
//     collegename: false,
//     yearofpassing: false,
//     percent: false,
//     withoutpay: false,
//     Employmenttype: false,
//     resumeFile: false,
//   });

//   const validateName = (name) => {
//     const nameRegex = /^[a-zA-Z\s]+$/;
//     return nameRegex.test(name);
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   useEffect(() => {
//     if (jobData && jobData.title) {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         title: jobData.title,
//       }));
//     }
//   }, [jobData]);

//   const [file, setFile] = useState(null);
//   const [uploadUrl, setUploadUrl] = useState('');
//   const [encoadUrl, setencodingUrl] = useState('');
//   const [fileName, setFileName] = useState('');

//   const handleFileChange = async (e) => {
//     const selectedFile = e.target.files[0];
//     const validFileTypes = [
//       'application/pdf',
//       'application/msword',
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//     ];

//     if (selectedFile && validFileTypes.includes(selectedFile.type)) {
//       setFile(selectedFile);
//       setFileName(selectedFile.name);
//       await requestUploadUrl(selectedFile);
//     } else {
//       alert('Please upload a valid PDF or DOC/DOCX file.');
//       fileInputRef.current.value = ''; // Clear the file input
//       setFile(null);
//       setFileName('');
//     }
//   };

//   const requestUploadUrl = async (file) => {
//     try {
//       const response = await axios.post(
//         // 'https://leapot-lms-backend.onrender.com/api/uploadResume',
//         'http://localhost:8000/api/uploadResume',
//         {
//           fileName: file.name,
//           fileType: file.type,
//         },
//       );
//       // console.log(response.data.url);
//       setUploadUrl(response.data.url);
//     } catch (error) {
//       console.error('Error requesting upload URL:', error);
//     }
//   };

//   const uploadToS3 = async () => {
//     try {
//       const data = await axios.put(uploadUrl, file, {
//         headers: {
//           'Access-Control-Allow-Origin': '*',
//           //   'Content-Type': file.type,
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setencodingUrl(encodeURIComponent(uploadUrl?.split('?')[0]));

//       console.log('File uploaded successfully', data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     let updatedValue = value;

//     if (name === 'name' || name === 'nationality') {
//       updatedValue = value.replace(/[^a-zA-Z\s]/g, '');
//     }

//     if (name === 'dob') {
//       updatedValue = value;
//     }

//     if (name === 'contactno') {
//       updatedValue = value.replace(/[^0-9]/g, '');
//     }

//     if (name === 'yearofpassing' || name === 'percent') {
//       updatedValue = value.replace(/[^0-9]/g, '');
//     }

//     setFormData({ ...formData, [name]: updatedValue });
//   };

//   const handleBlur = (e) => {
//     const { name, value } = e.target;
//     let error = false;

//     switch (name) {
//       case 'name':
//         if (value === '' || !validateName(value)) {
//           error = true;
//         }
//         break;
//       case 'dob':
//       case 'nationality':
//         if (value === '') {
//           error = true;
//         }
//         break;
//       case 'contactno':
//         if (value === '' || value.replace(/\D/g, '').length === 0) {
//           error = true;
//         }
//         break;
//       case 'email':
//         if (value === '' || !validateEmail(value)) {
//           error = true;
//         }
//         break;
//       case 'collegename':
//       case 'yearofpassing':
//       case 'percent':
//         if (value === '') {
//           error = true;
//         }
//         break;
//       case 'resumeLink': {
//         const fileInput = document.querySelector('input[name="resumeLink"]');
//         const file = fileInput ? fileInput.files[0] : null;
//         const allowedTypes = [
//           'application/pdf',
//           'application/msword',
//           'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//         ];
//         if (!file || !allowedTypes.includes(file.type)) {
//           error = true;
//         }
//         break;
//       }
//       default:
//         break;
//     }

//     setErrorState((prevState) => ({
//       ...prevState,
//       [name]: error,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const requiredFields = [
//       'name',
//       'dob',
//       'nationality',
//       'contactno',
//       'email',
//       'collegename',
//       'yearofpassing',
//       'percent',
//       'withoutpay',
//       'Employmenttype',
//     ];

//     const emptyFields = requiredFields.filter((field) => !formData[field]);

//     // Update errorState for empty fields
//     const newErrorState = {};
//     requiredFields.forEach((field) => {
//       newErrorState[field] = !formData[field];
//     });
//     setErrorState(newErrorState);

//     // Scroll to the first empty field if there are empty fields
//     if (emptyFields.length > 0) {
//       const firstEmptyField = document.querySelector(
//         `[name="${emptyFields[0]}"]`,
//       );
//       if (firstEmptyField) {
//         firstEmptyField.scrollIntoView({ behavior: 'smooth', block: 'center' });
//       }
//       return;
//     }

//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailPattern.test(formData.email)) {
//       setErrorState((prevState) => ({
//         ...prevState,
//         email: true,
//       }));
//       return;
//     }

//     try {
//       await uploadToS3();
//       const response = await axios.post(
//         // 'https://leapot-lms-backend.onrender.com/api/addJobApplication',
//         'http://localhost:8000/api/addJobApplication',
//         {
//           ...formData,
//           resumeLink: uploadUrl?.split('?')[0],
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         },
//       );
//       console.log(response)
//       if (response.status = 200) {
//         console.log('Job application submitted successfully!');
//         window.alert('Job application submitted successfully!');
//         navigate('/Careers');
//       } else {
//         console.error('Failed to submit job application');
//       }
//     } catch (error) {
//       console.error('Error submitting job application:', error.message);
//     }
//   };

//   return (
//     <>
//       <MainContainer>
//         <Container1>
//           {jobData && (
//             <>
//               <Heading>Please fill in your information</Heading>
//               <Heading2>{jobData.title}</Heading2>
//             </>
//           )}

//           <Container2>
//             <Heading3>Note: All the fields are mandatory</Heading3>
//             <form>
//               <Heading4>Personal Information</Heading4>
//               <Container2B>
//                 <Container2AB>
//                   <FieldLabel>Title:</FieldLabel>
//                   <span>{formData.title}</span>
//                 </Container2AB>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Name"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.name}
//                       helperText={
//                         errorState.name ? 'Please enter your name' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       type="date"
//                       label="Enter DOB"
//                       name="dob"
//                       value={formData.dob}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.dob}
//                       helperText={
//                         errorState.dob ? 'Please enter your date of birth' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Nationality"
//                       name="nationality"
//                       value={formData.nationality}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.nationality}
//                       helperText={
//                         errorState.nationality
//                           ? 'Please enter your nationality'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Heading5>Contact Information</Heading5>
//               <Container2B>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Contact No"
//                       type="tel"
//                       name="contactno"
//                       value={formData.contactno}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.contactno}
//                       helperText={
//                         errorState.contactno
//                           ? 'Please enter your contact number'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Email Address"
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.email}
//                       helperText={
//                         errorState.email
//                           ? 'Please enter a valid email address'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Heading5>Educational Information</Heading5>
//               <Container2B>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="College Name"
//                       name="collegename"
//                       value={formData.collegename}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.collegename}
//                       helperText={
//                         errorState.collegename
//                           ? 'Please enter your college name'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Year of Passing"
//                       type="number"
//                       name="yearofpassing"
//                       value={formData.yearofpassing}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.yearofpassing}
//                       helperText={
//                         errorState.yearofpassing
//                           ? 'Please enter your year of passing'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Percentage"
//                       type="number"
//                       name="percent"
//                       value={formData.percent}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.percent}
//                       helperText={
//                         errorState.percent ? 'Please enter your percentage' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Container2CMain>
//                 <Container2C>
//                   <Heading5>Other Information</Heading5>
//                   <Container2A>
//                     <FieldLabel>
//                       Are you applying for unpaid internship?
//                     </FieldLabel>
//                     <FiledCont>
//                       <FieldLabel2>
//                         <RadioGroup
//                           aria-label="withoutpay"
//                           name="withoutpay"
//                           value={formData.withoutpay}
//                           onChange={handleChange}
//                           required
//                           style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             flexDirection: 'row',
//                           }}
//                         >
//                           <FormControlLabel
//                             value="yes"
//                             control={<Radio />}
//                             label="Yes"
//                           />
//                           <FormControlLabel
//                             value="no"
//                             control={<Radio />}
//                             label="No"
//                           />
//                         </RadioGroup>
//                       </FieldLabel2>
//                     </FiledCont>
//                   </Container2A>
//                   <Container2A>
//                     <FieldLabel>Employment Type:</FieldLabel>
//                     <FiledCont>
//                       <FieldLabel2>
//                         <RadioGroup
//                           aria-label="Employmenttype"
//                           name="Employmenttype"
//                           value={formData.Employmenttype}
//                           onChange={handleChange}
//                           required
//                           style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             flexDirection: 'row',
//                           }}
//                         >
//                           <FormControlLabel
//                             value="full-time"
//                             control={<Radio />}
//                             label="Full-time"
//                           />
//                           <FormControlLabel
//                             value="part-time"
//                             control={<Radio />}
//                             label="Part-time"
//                           />
//                         </RadioGroup>
//                       </FieldLabel2>
//                     </FiledCont>
//                   </Container2A>
//                 </Container2C>
//                 <Container2CB>
//                   <Heading5>Upload Your Resume</Heading5>
//                   <Container2A>
//                     <TextField
//                       type="file"
//                       onChange={handleFileChange}
//                       ref={fileInputRef}
//                       // style={{ display: 'none' }}
//                       required
//                     />
//                     <UploadFileBtn onClick={() => fileInputRef.current.click()}>
//                       Choose File
//                     </UploadFileBtn>
//                     {fileName && (
//                       <RemoveButtonCont>
//                         Selected file: {fileName}
//                         <RemoveButton onClick={handleRemoveFile}>
//                           X
//                         </RemoveButton>
//                       </RemoveButtonCont>
//                     )}
//                   </Container2A>
//                 </Container2CB>
//               </Container2CMain>
//             </form>
//           </Container2>
//           <Submitbtn onClick={handleSubmit} type="submit">
//             Submit
//           </Submitbtn>
//         </Container1>
//       </MainContainer>
//     </>
//   );
// };

// export default JobApplication;

// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import {
//   MainContainer,
//   Container2,
//   FieldLabel,
//   FieldLabel2,
//   FiledCont,
//   Container1,
//   Heading,
//   Heading2,
//   Heading3,
//   Heading4,
//   Heading5,
//   Container2A,
//   Container2B,
//   Submitbtn,
//   Container2AB,
//   Container2C,
//   InputFiledStyle,
//   UploadFileBtn,
//   RemoveButton,
//   RemoveButtonCont,
//   Container2CMain,
//   Container2CB,
// } from '../styles/JobApplicationStyle';

// import axios from 'axios';

// import { TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';

// import Grid from '@mui/material/Grid';

// const JobApplication = (props) => {
//   const { id } = useParams();
//   const [jobData, setJobData] = useState(null);
//   const navigate = useNavigate();

//   const fileInputRef = useRef();

//   const handleRemoveFile = () => {
//     fileInputRef.current.value = '';
//     setFile(null);
//     setFileName('');
//   };

//   useEffect(() => {
//     const fetchJobData = async () => {
//       try {
//         const response = await axios.get(
//           `https://leapot-lms-backend.onrender.com/api/getJobData/${id}`,
//         );
//         setJobData(response.data.job);
//       } catch (error) {
//         console.error('Error fetching job data:', error);
//       }
//     };

//     fetchJobData();
//   }, [id]);

//   const [formData, setFormData] = useState({
//     title: jobData && jobData.title ? jobData.title : 'Default Title',
//     name: '',
//     dob: '',
//     nationality: '',
//     contactno: '',
//     email: '',
//     collegename: '',
//     yearofpassing: '',
//     percent: '',
//     withoutpay: '',
//     Employmenttype: '',
//   });

//   const [errorState, setErrorState] = useState({
//     name: false,
//     dob: false,
//     nationality: false,
//     contactno: false,
//     email: false,
//     collegename: false,
//     yearofpassing: false,
//     percent: false,
//     withoutpay: false,
//     Employmenttype: false,
//     resumeLink:false,
//   });

//   const validateName = (name) => {
//     const nameRegex = /^[a-zA-Z\s]+$/;
//     return nameRegex.test(name);
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   useEffect(() => {
//     if (jobData && jobData.title) {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         title: jobData.title,
//       }));
//     }
//   }, [jobData]);

//   const [file, setFile] = useState(null);
//   const [uploadUrl, setUploadUrl] = useState('');
//   const [encoadUrl, setencodingUrl] = useState('');
//   const [fileName, setFileName] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);

//   // const handleFileChange = async (e) => {
//   //   const selectedFile = e.target.files[0];
//   //   const validFileTypes = [
//   //     'application/pdf',
//   //     'application/msword',
//   //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//   //   ];

//   //   if (selectedFile && validFileTypes.includes(selectedFile.type)) {
//   //     setFile(selectedFile);
//   //     setFileName(selectedFile.name);
//   //     await requestUploadUrl(selectedFile);
//   //   } else {
//   //     alert('Please upload a valid PDF or DOC/DOCX file.');
//   //     fileInputRef.current.value = ''; // Clear the file input
//   //     setFile(null);
//   //     setFileName('');
//   //   }
//   // };

//   const handleFileChange = async (e) => {
//     const selectedFile = e.target.files[0];
//     const validFileTypes = [
//       'application/pdf',
//       'application/msword',
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//     ];

//     if (selectedFile && validFileTypes.includes(selectedFile.type)) {
//       setFile(selectedFile);
//       setFileName(selectedFile.name);
//       await requestUploadUrl(selectedFile);
//     } else {
//       alert('Please upload a valid PDF or DOC/DOCX file.');
//       fileInputRef.current.value = ''; // Clear the file input
//       setFile(null);
//       setFileName('');
//     }
//   };

//   const requestUploadUrl = async (file) => {
//     try {
//       const response = await axios.post(
//         'https://leapot-lms-backend.onrender.com/api/uploadResume',
//         {
//           fileName: file.name,
//           fileType: file.type,
//         },
//       );
//       // console.log(response.data.url);
//       setUploadUrl(response.data.url);
//     } catch (error) {
//       console.error('Error requesting upload URL:', error);
//     }
//   };

//   const uploadToS3 = async () => {
//     try {
//       const data = await axios.put(uploadUrl, file, {
//         headers: {
//           'Access-Control-Allow-Origin': '*',
//           //   'Content-Type': file.type,
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       setencodingUrl(encodeURIComponent(uploadUrl?.split('?')[0]));

//       console.log('File uploaded successfully', data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     let updatedValue = value;

//     if (name === 'name' || name === 'nationality') {
//       updatedValue = value.replace(/[^a-zA-Z\s]/g, '');
//     }

//     if (name === 'dob') {
//       updatedValue = value;
//     }

//     if (name === 'contactno') {
//       updatedValue = value.replace(/[^0-9]/g, '');
//     }

//     if (name === 'yearofpassing' || name === 'percent') {
//       updatedValue = value.replace(/[^0-9]/g, '');
//     }

//     setFormData({ ...formData, [name]: updatedValue });
//   };

//   const handleBlur = (e) => {
//     const { name, value } = e.target;
//     let error = false;

//     switch (name) {
//       case 'name':
//         if (value === '' || !validateName(value)) {
//           error = true;
//         }
//         break;
//       case 'dob':
//       case 'nationality':
//         if (value === '') {
//           error = true;
//         }
//         break;
//       case 'contactno':
//         if (value === '' || value.replace(/\D/g, '').length === 0) {
//           error = true;
//         }
//         break;
//       case 'email':
//         if (value === '' || !validateEmail(value)) {
//           error = true;
//         }
//         break;
//       case 'collegename':
//       case 'yearofpassing':
//       case 'percent':
//         if (value === '') {
//           error = true;
//         }
//         break;
//       default:
//         break;
//     }

//     setErrorState((prevState) => ({
//       ...prevState,
//       [name]: error,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const requiredFields = [
//       'name',
//       'dob',
//       'nationality',
//       'contactno',
//       'email',
//       'collegename',
//       'yearofpassing',
//       'percent',
//       'withoutpay',
//       'Employmenttype',
//       'resumeLink',
//     ];

//     const emptyFields = requiredFields.filter((field) => !formData[field]);

//     // Update errorState for empty fields
//     const newErrorState = {};
//     requiredFields.forEach((field) => {
//       newErrorState[field] = !formData[field];
//     });
//     setErrorState(newErrorState);
//     // Scroll to the first empty field if there are empty fields
//     if (emptyFields.length > 0) {
//       const firstEmptyField = document.querySelector(
//         `[name="${emptyFields[0]}"]`,
//       );
//       if (firstEmptyField) {
//         firstEmptyField.scrollIntoView({ behavior: 'smooth', block: 'center' });
//       }
//       return;
//     }

//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailPattern.test(formData.email)) {
//       setErrorState((prevState) => ({
//         ...prevState,
//         email: true,
//       }));
//       return;
//     }

//     try {
//       await uploadToS3();
//       const response = await axios.post(
//         'https://leapot-lms-backend.onrender.com/api/addJobApplication',
//         {
//           ...formData,
//           resumeLink: uploadUrl?.split('?')[0],
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         },
//       );
//       if (response.status === 200)  {
//         console.log('Job application submitted successfully!');
//         window.alert('Job application submitted successfully!');
//         navigate('/Careers');
//       } else {
//         console.error('Failed to submit job application');
//       }
//     } catch (error) {
//       console.error('Error submitting job application:', error);
//     }
//   };

//   return (
//     <>
//       <MainContainer>
//         <Container1>
//           {jobData && (
//             <>
//               <Heading>Please fill in your information</Heading>
//               <Heading2>{jobData.title}</Heading2>
//             </>
//           )}

//           <Container2>
//             <Heading3>Note: All the fields are mandatory</Heading3>
//             <form>
//               <Heading4>Personal Information</Heading4>
//               <Container2B>
//                 <Container2AB>
//                   <FieldLabel>Title:</FieldLabel>
//                   <span>{formData.title}</span>
//                 </Container2AB>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Name"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.name}
//                       helperText={
//                         errorState.name ? 'Please enter your name' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       type="date"
//                       label="Enter DOB"
//                       name="dob"
//                       value={formData.dob}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.dob}
//                       helperText={
//                         errorState.dob ? 'Please enter your date of birth' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Nationality"
//                       name="nationality"
//                       value={formData.nationality}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.nationality}
//                       helperText={
//                         errorState.nationality
//                           ? 'Please enter your nationality'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Heading5>Contact Information</Heading5>
//               <Container2B>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Contact No"
//                       type="tel"
//                       name="contactno"
//                       value={formData.contactno}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.contactno}
//                       helperText={
//                         errorState.contactno
//                           ? 'Please enter your contact number'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Email Address"
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.email}
//                       helperText={
//                         errorState.email
//                           ? 'Please enter a valid email address'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Heading5>Educational Information</Heading5>
//               <Container2B>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="College Name"
//                       name="collegename"
//                       value={formData.collegename}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.collegename}
//                       helperText={
//                         errorState.collegename
//                           ? 'Please enter your college name'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Year of Passing"
//                       type="number"
//                       name="yearofpassing"
//                       value={formData.yearofpassing}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.yearofpassing}
//                       helperText={
//                         errorState.yearofpassing
//                           ? 'Please enter your year of passing'
//                           : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
//                     <TextField
//                       label="Percentage"
//                       type="number"
//                       name="percent"
//                       value={formData.percent}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       sx={InputFiledStyle}
//                       required
//                       error={errorState.percent}
//                       helperText={
//                         errorState.percent ? 'Please enter your percentage' : ''
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </Container2B>
//               <Container2CMain>
//                 <Container2C>
//                   <Heading5>Other Information</Heading5>
//                   <Container2A>
//                     <FieldLabel>
//                       Are you applying for unpaid internship?
//                     </FieldLabel>
//                     <FiledCont>
//                       <FieldLabel2>
//                         <RadioGroup
//                           aria-label="withoutpay"
//                           name="withoutpay"
//                           value={formData.withoutpay}
//                           onChange={handleChange}
//                           required
//                           style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             flexDirection: 'row',
//                           }}
//                         >
//                           <FormControlLabel
//                             value="yes"
//                             control={<Radio />}
//                             label="Yes"
//                           />
//                           <FormControlLabel
//                             value="no"
//                             control={<Radio />}
//                             label="No"
//                           />
//                         </RadioGroup>
//                       </FieldLabel2>
//                     </FiledCont>
//                   </Container2A>
//                   <Container2A>
//                     <FieldLabel>Employment Type:</FieldLabel>
//                     <FiledCont>
//                       <FieldLabel2>
//                         <RadioGroup
//                           aria-label="Employmenttype"
//                           name="Employmenttype"
//                           value={formData.Employmenttype}
//                           onChange={handleChange}
//                           required
//                           style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             flexDirection: 'row',
//                           }}
//                         >
//                           <FormControlLabel
//                             value="full-time"
//                             control={<Radio />}
//                             label="Full-time"
//                           />
//                           <FormControlLabel
//                             value="part-time"
//                             control={<Radio />}
//                             label="Part-time"
//                           />
//                         </RadioGroup>
//                       </FieldLabel2>
//                     </FiledCont>
//                   </Container2A>
//                 </Container2C>
//                 <Container2CB>
//                   <Heading5>Upload Your Resume</Heading5>
//                   <Container2A>
//                       <TextField
//                         type="file"
//                         label="Choose file"
//                         name='resumeLink'
//                         onChange={handleFileChange}
//                         ref={fileInputRef}
//                         // style={{ display: 'none' }}
//                         required
//                         sx={InputFiledStyle}
//                         error={errorState.fileformat}
//                         helperText={
//                           errorState.fileformat
//                             ? 'Please attach pdf or docs'
//                             : ''
//                         }
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                       {/* <UploadFileBtn onClick={() => fileInputRef.current.click()}>
//                       Choose File
//                     </UploadFileBtn>
//                     {fileName && (
//                       <RemoveButtonCont>
//                         Selected file: {fileName}
//                         <RemoveButton onClick={handleRemoveFile}>
//                           X
//                         </RemoveButton>
//                       </RemoveButtonCont>
//                     )} */}
//                       {selectedFile && (
//                         <Box mt={2}>
//                           <Button
//                             variant="contained"
//                             color="secondary"
//                             onClick={handleRemoveFile}
//                           >
//                             Remove File
//                           </Button>
//                         </Box>
//                       )}
//                   </Container2A>
//                 </Container2CB>
//               </Container2CMain>
//             </form>
//           <Submitbtn onClick={handleSubmit} type="submit">
//             Submit
//           </Submitbtn>
//           </Container2>
//         </Container1>
//       </MainContainer>
//     </>
//   );
// };

// export default JobApplication;

// code 3

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  MainContainer,
  Container2,
  FieldLabel,
  FieldLabel2,
  FiledCont,
  Container1,
  Heading,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Container2A,
  Container2B,
  Submitbtn,
  Container2AB,
  Container2C,
  InputFiledStyle,
  UploadFileBtn,
  RemoveButton,
  RemoveButtonCont,
  Container2CMain,
  Container2CB,
} from '../styles/JobApplicationStyle';

import axios from 'axios';

import { TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';

import Grid from '@mui/material/Grid';

const JobApplication = (props) => {
  const { id } = useParams();
  const [jobData, setJobData] = useState(null);
  const navigate = useNavigate();

  const fileInputRef = useRef();

  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
    setFile(null);
    setFileName('');
  };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          // `http://localhost:8000/api/getJobData/${id}`,
          `https://leapot-lms-backend.onrender.com/api/getJobData/${id}`,
        );
        setJobData(response.data.job);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    fetchJobData();
  }, [id]);

  const [formData, setFormData] = useState({
    title: jobData && jobData.title ? jobData.title : 'Default Title',
    name: '',
    dob: '',
    nationality: '',
    contactno: '',
    email: '',
    collegename: '',
    yearofpassing: '',
    percent: '',
    withoutpay: '',
    Employmenttype: '',
    resumeFile: null,
  });

  const [errorState, setErrorState] = useState({
    name: false,
    dob: false,
    nationality: false,
    contactno: false,
    email: false,
    collegename: false,
    yearofpassing: false,
    percent: false,
    withoutpay: false,
    Employmenttype: false,
    fileformat: false,
  });

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (jobData && jobData.title) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        title: jobData.title,
      }));
    }
  }, [jobData]);

  const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState('');
  const [encoadUrl, setencodingUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setErrorState({ fileformat: false });
      await requestUploadUrl(selectedFile);
    } else {
      // alert('Please upload a valid PDF or DOC/DOCX file.');
      fileInputRef.current.value = ''; // Clear the file input
      e.target.value = null;
      setErrorState({ fileformat: true });
      setFile(null);
      setFileName('');
    }
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        // 'http://localhost:8000/api/uploadResume',
        'https://leapot-lms-backend.onrender.com/api/uploadResume',
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      // console.log(response.data.url);
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      const data = await axios.put(uploadUrl, file, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          //   'Content-Type': file.type,
          'Content-Type': 'multipart/form-data',
        },
      });
      setencodingUrl(encodeURIComponent(uploadUrl?.split('?')[0]));

      console.log('File uploaded successfully', data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (name === 'name' || name === 'nationality') {
      updatedValue = value.replace(/[^a-zA-Z\s]/g, '');
    }

    if (name === 'dob') {
      updatedValue = value;
    }

    if (name === 'contactno') {
      updatedValue = value.replace(/[^0-9]/g, '');
    }

    if (name === 'yearofpassing' || name === 'percent') {
      updatedValue = value.replace(/[^0-9]/g, '');
    }

    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let error = false;

    switch (name) {
      case 'name':
        if (value === '' || !validateName(value)) {
          error = true;
        }
        break;
      case 'dob':
      case 'nationality':
        if (value === '') {
          error = true;
        }
        break;
      case 'contactno':
        if (value === '' || value.replace(/\D/g, '').length === 0) {
          error = true;
        }
        break;
      case 'email':
        if (value === '' || !validateEmail(value)) {
          error = true;
        }
        break;
      case 'collegename':
      case 'yearofpassing':
      case 'percent':
        if (value === '') {
          error = true;
        }
        break;
      case 'resumeLink': {
        const fileInput = document.querySelector('input[name="resumeLink"]');
        const file = fileInput ? fileInput.files[0] : null;
        const allowedTypes = [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        if (!file || !allowedTypes.includes(file.type)) {
          error = true;
        }
        break;
      }
      default:
        break;
    }

    setErrorState((prevState) => ({
      ...prevState,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'name',
      'dob',
      'nationality',
      'contactno',
      'email',
      'collegename',
      'yearofpassing',
      'percent',
      'withoutpay',
      'Employmenttype',
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    // Update errorState for empty fields
    const newErrorState = {};
    requiredFields.forEach((field) => {
      newErrorState[field] = !formData[field];
    });
    setErrorState(newErrorState);

    // Scroll to the first empty field if there are empty fields
    if (emptyFields.length > 0) {
      const firstEmptyField = document.querySelector(
        `[name="${emptyFields[0]}"]`,
      );
      if (firstEmptyField) {
        firstEmptyField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setErrorState((prevState) => ({
        ...prevState,
        email: true,
      }));
      return;
    }

    try {
      await uploadToS3();
      const response = await axios.post(
        // 'http://localhost:8000/api/addJobApplication',
        'https://leapot-lms-backend.onrender.com/api/addJobApplication',
        {
          ...formData,
          resumeLink: uploadUrl?.split('?')[0],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      console.log(response);
      if ((response.status = 200)) {
        console.log('Job application submitted successfully!');
        window.alert('Job application submitted successfully!');
        navigate('/Careers');
      } else {
        console.error('Failed to submit job application');
      }
    } catch (error) {
      console.error('Error submitting job application:', error.message);
    }
  };

  return (
    <>
      <MainContainer>
        <Container1>
          {jobData && (
            <>
              <Heading>Please fill in your information</Heading>
              <Heading2>{jobData.title}</Heading2>
            </>
          )}

          <Container2>
            <Heading3>Note: All the fields are mandatory</Heading3>
            <form>
              <Heading4>Personal Information</Heading4>
              <Container2B>
                <Container2AB>
                  <FieldLabel>Title:</FieldLabel>
                  <span>{formData.title}</span>
                </Container2AB>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.name}
                      helperText={
                        errorState.name ? 'Please enter your name' : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      type="date"
                      label="Enter DOB"
                      name="dob"
                      value={formData.dob}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.dob}
                      helperText={
                        errorState.dob ? 'Please enter your date of birth' : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Nationality"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.nationality}
                      helperText={
                        errorState.nationality
                          ? 'Please enter your nationality'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container2B>
              <Heading5>Contact Information</Heading5>
              <Container2B>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Contact No"
                      type="tel"
                      name="contactno"
                      value={formData.contactno}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.contactno}
                      helperText={
                        errorState.contactno
                          ? 'Please enter your contact number'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Email Address"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.email}
                      helperText={
                        errorState.email
                          ? 'Please enter a valid email address'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container2B>
              <Heading5>Educational Information</Heading5>
              <Container2B>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="College Name"
                      name="collegename"
                      value={formData.collegename}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.collegename}
                      helperText={
                        errorState.collegename
                          ? 'Please enter your college name'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Year of Passing"
                      type="number"
                      name="yearofpassing"
                      value={formData.yearofpassing}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.yearofpassing}
                      helperText={
                        errorState.yearofpassing
                          ? 'Please enter your year of passing'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <TextField
                      label="Percentage"
                      type="number"
                      name="percent"
                      value={formData.percent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={InputFiledStyle}
                      required
                      error={errorState.percent}
                      helperText={
                        errorState.percent ? 'Please enter your percentage' : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container2B>
              <Container2CMain>
                <Container2C>
                  <Heading5>Other Information</Heading5>
                  <Container2A>
                    <FieldLabel>
                      Are you applying for unpaid internship?
                    </FieldLabel>
                    <FiledCont>
                      <FieldLabel2>
                        <RadioGroup
                          aria-label="withoutpay"
                          name="withoutpay"
                          value={formData.withoutpay}
                          onChange={handleChange}
                          required
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FieldLabel2>
                    </FiledCont>
                  </Container2A>
                  <Container2A>
                    <FieldLabel>Employment Type:</FieldLabel>
                    <FiledCont>
                      <FieldLabel2>
                        <RadioGroup
                          aria-label="Employmenttype"
                          name="Employmenttype"
                          value={formData.Employmenttype}
                          onChange={handleChange}
                          required
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <FormControlLabel
                            value="full-time"
                            control={<Radio />}
                            label="Full-time"
                          />
                          <FormControlLabel
                            value="part-time"
                            control={<Radio />}
                            label="Part-time"
                          />
                        </RadioGroup>
                      </FieldLabel2>
                    </FiledCont>
                  </Container2A>
                </Container2C>
                <Container2CB>
                  <Heading5>Upload Your Resume</Heading5>
                  <Container2A>
                    <TextField
                      type="file"
                      name="resumeLink"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      // style={{ display: 'none' }}
                      sx={InputFiledStyle}
                      error={errorState.fileformat}
                      required
                      helperText={
                        errorState.fileformat ? 'Please attach pdf or docs' : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Container2A>
                </Container2CB>
              </Container2CMain>
            </form>
          </Container2>
          <Submitbtn onClick={handleSubmit} type="submit">
            Submit
          </Submitbtn>
        </Container1>
      </MainContainer>
    </>
  );
};

export default JobApplication;
