import React, { useState, useEffect } from 'react';
import {
  BlogContainer,
  MainTitle,
  HeaderImage,
  MainHeading,
  TitleHeading,
  TitleDash,
  MainCardConatiner,
  Heading,
  BlogParagraph,
  HeadCont,
} from '../../styles/ArticlesStyle';

import blog from '../../images/blog.jpg';
import MediaCard from './MediaCard';
import ScrollToTop from '../ScrollToTop';

const Blog = (scrollToTop, classes, isVisible) => {
  const [blogs, setBlogs] = useState([]);

  return (
    <BlogContainer>
      <HeadCont>
        <MainTitle
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="300"
          data-aos-delay="150"
        >
          Blogs
        </MainTitle>
        <TitleDash
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
          data-aos-delay="150"
        />
      </HeadCont>

      <BlogParagraph>
        Welcome to the Leapot Technologies Blogs, your gateway to insightful
        content covering a spectrum of topics ranging from eLearning content
        development to Learning Management Systems (LMS), staffing services, and
        custom software development. Our blog serves as a knowledge hub for
        professionals seeking to deepen their understanding of these domains,
        offering expert insights, best practices, and industry trends. Whether
        you're an eLearning enthusiast, an HR manager exploring staffing
        solutions, or a tech enthusiast curious about custom software
        development, our carefully curated articles provide valuable information
        to empower your endeavors. Stay tuned as we explore the ever-evolving
        landscape of technology, education, and workforce solutions, guiding you
        towards informed decisions and innovative solutions.
      </BlogParagraph>

      <HeaderImage bgImage={blog}>
        <MainHeading>
          <TitleHeading
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="300"
          >
            Discover the Power of Words
            <br />
            Come along, Participate, and Grow with Us
          </TitleHeading>
        </MainHeading>
      </HeaderImage>

      <MainCardConatiner>
        <HeadCont>
          <Heading
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="150"
          >
            Recent Articles
          </Heading>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="150"
          />
        </HeadCont>
        <MediaCard />
      </MainCardConatiner>
      <ScrollToTop
        isVisible={isVisible}
        scrollToTop={scrollToTop}
        classes={classes}
      />
    </BlogContainer>
  );
};

export default Blog;
