import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';

export const TermsContainer = styled.div`
  /* background-color: ${palette.black}; */
  ${tw`m-24 mt-28 mb-10 flex justify-center items-center flex-col`}
`;

export const Container = styled.div`
  ${tw`mt-0`}
`;

export const MainHeading = styled.h1`
  ${tw`flex justify-center items-center text-4xl
  sm:text-lg
  md:text-xl
  lg:text-3xl
  xl:text-4xl
  2xl:text-4xl`}
  color:${palette.primary}
`;


export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[20%] rounded-lg mb-5`}
  background:${palette.primary}
`;

export const TitleHeading = styled.h1`
  ${tw`flex justify-center items-center text-4xl mt-[-160px]
  sm:text-lg
  md:text-xl
  lg:text-3xl
  xl:text-4xl
  2xl:text-2xl `}
  position: absolute;
  color: ${palette.white};

  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InnerContainer = styled.div`
  ${tw`mt-10`}
`;

export const Heading = styled.h1`
  ${tw` text-sm
  sm:text-base
  md:text-lg
  lg:text-xl
  xl:text-2xl
  2xl:text-2xl `}
  color:${palette.primary}
`;

// export const Paragraph = styled.p`
//   ${tw`text-justify text-xl
//   sm:text-sm
//   md:text-base
//   lg:text-lg
//   xl:text-xl
//   2xl:text-xl `}
// `;

export const Paragraph = styled.p`
${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8
lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
color: ${palette.black};
`;

export const SpanTag2 = styled.a`
${tw`cursor-pointer no-underline`}
color:${palette.primary}
`;

export const NavLink = styled.a``;


