import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";


export const SliderContainer = styled.div`
  ${tw`mt-20`}
`;

// new header 

export const MainHeadCont = styled.div`
  ${tw`flex justify-around items-center h-[45vh]
    sm:h-[50vh]
  `}
  background:${palette.secondary}
`;

export const Title = styled.h1`
  ${tw`text-white text-lg font-medium
  2xl:text-4xl
  xl:text-3xl
  lg:text-2xl
  md:text-lg
  `}
`;

export const Desc = styled.p`
  ${tw`w-[85vw] text-white text-sm sm:leading-6 text-justify
  2xl:text-lg 2xl:leading-8
  xl:text-lg xl:leading-8
  lg:text-base lg:leading-8
  md:text-sm  md:leading-7 md:w-[50vw]
  sm:text-base sm:leading-6
  tracking-wide
  `}
`;

//   ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify
//   lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-6  tracking-wide`}
//   color: ${palette.black};
//   line-height: 40px;
// `;


export const MainDesc = styled.div`
  ${tw`mt-5 
  2xl:mt-24
  lg:mt-5`}
`;

export const HeaderImage = styled.img`
  ${tw`hidden w-[30%] h-auto mt-10
  md:block
  `}
`;
