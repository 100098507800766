// code 2

import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../styles/palette";

export const MainContainer = styled.div`
  ${tw`flex justify-around flex-col items-center   mt-10
  md:flex-row md:m-10   
  lg:m-20 lg:mt-10
  xl:m-40 xl:mt-10
  `}
  border-radius:20px;
  background:${palette.tertiary};
`;


export const Header = styled.h1`
  ${tw` flex justify-center text-4xl font-bold leading-8 tracking-wide w-[100%]`}
  color:${palette.white}
`;

export const Desc = styled.p`
  ${tw` flex justify-center 2xl:text-2xl xl:text-lg lg:text-base mt-10 `}
  Color:${palette.white}
`;

export const ContactForm = styled.div`
  ${tw`2xl:text-2xl flex flex-col w-[200px] h-[350px] p-16 mb-20 mt-20 
  sm:w-[300px] sm:h-[380px] sm:m-10
  md:w-[320px] md:h-[400px]
  lg:w-[450px] lg:h-[400px]
  xl:w-[600px] xl:h-[400px]
  `}
  background : ${palette.white};
  box-shadow: 5px 5px 30px 0px black;

//  background: linear-gradient(90deg, #389dfc, #025099);
  border-radius: 20px;
//   margin-top: 0px;
`;


export const TextFieldStyle = {
  marginTop:"10px",
  "& .MuiInputBase-root ": {
    // fontSize: "1rem",
    // background: `${palette.white}`,
    // height: "50px",
    // marginTop: "20px",
    // border:"1px solid #CACACA",
    // // boxShadow: "0px 0px 2px 0 black",
    // width: "100%",
    fontSize: '1rem',
    background: `${palette.white}`,
    height: '45px',
    marginTop: '5px',
    width: '100%',

    '@media (min-width: 340px)': {
      width: '250px', // Adjust the width for smaller screens
    },

    '@media (min-width: 361px)': {
      width: '250px', // Adjust the width for smaller screens
    },

    '@media (min-width: 640px)': {
      width: '300px', // Adjust the width for smaller screens
    },

    '@media (min-width: 768px)': {
      width: '350px', // Adjust the width for smaller screens
    },

    '@media (min-width: 1024px)': {
      width: '380px', // Adjust the width for smaller screens
    },

    '@media (min-width: 1200px)': {
      width: '450px', // Adjust the width for smaller screens
    },

    '@media (min-width: 1536px)': {
      width: '490px', // Adjust the width for smaller screens
    },

    '@media (min-width: 1536px)': {
      width: '550px', // Adjust the width for smaller screens
    },

  },
};


export const TextFieldStyleA = {
  "& .MuiInputBase-root ": {
    fontSize: "1rem",
    background: `${palette.white}`,
    height: "50px",
    marginTop: "-30px",
    // boxShadow: "0px 0px 2px 0 black",
    border:"1px solid #CACACA",
    width: "100%",
  },
};

export const SubmitButton = {
  color: palette.white,
  background: palette.tertiary,
  margin: "auto",
  fontWeight: 600,
  width: "12rem",
  height: "2.5rem",
  textAlign: "center",
  marginTop: "1.5rem",
  boxShadow: "0px 0px 5px #000000",
};


export const TextFieldMsg = styled.div`
${tw`mt-5 flex justify-start items-start`}
`

export const TextFieldMain = styled.div`
${tw` flex flex-col justify-center items-center mt-[-50px] sm:mt-[-20px]`}
`

export const ContactDescMain = styled.div`
  ${tw`flex flex-col w-[50%] justify-center items-center mt-20 ml-5
lg:mt-0
`}
`;

export const TextFieldMsgStyle = {
  "& .MuiInputBase-root ": {
    fontSize: "1rem",
    background: `${palette.white}`,
    marginTop: "10px",
    boxShadow: "0px 0px 1px 0px black",
    width: "100%",
    displa:'flex',
    justifyContent:'center',
    alignItems:'center',


    '@media (min-width: 340px)': {
      marginLeft:'-25px',
      width: '250px',
  },

    '@media (min-width: 640px)': {
      marginLeft:'0',
      width: '300px',
  },

    '@media (min-width: 768px)': {
      marginLeft:'-15px',
      width: '350px',
  },

    '@media (min-width: 1024px)': {
      marginLeft:'0',
      width: '380px',
  },

    '@media (min-width: 1200px)': {
      marginLeft:'0',
      width: '450px',
  },

    '@media (min-width: 1535px)': {
      marginLeft:'0',
      width: '550px',
  },

  },

  '@media (max-width: 600px)': { 
    "& .MuiInputBase-root": {
      height: "100px", 
    }
  },


} 



export const PhoneInputContainer = styled.div`
${tw`mt-5 w-[100%]`}
  & .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-bottom: 0 !important;
    // padding-left: 48px;
    margin-left: 10px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 50px; /* Changed height to 50px */
    width: 100%;
    outline: none;
  }


  & .react-tel-input {
    width: 100%;
    margin-left: -5px;
  }

  @media (min-width: 260px) {
    width: 300px;
  }

  @media (min-width: 280px) {
    width: 240px;
  }

  // @media (min-width: 375px) {
  //   width: 200px;
  // }

  // @media (min-width: 400px) {
  //   width: 200px;
  // }

  // @media (min-width: 500px) {
  //   width: 200px;
  // }

  @media (min-width: 640px) {
    width: 290px;
  }
  
  @media (min-width: 768px) {
    width: 340px;
  }

  // @media (min-width: 992px) {
  //   width: 320px;
  // }

  // @media (min-width: 1000px) {
  //   width: 320px;
  // }

  @media (min-width: 1024px) {
    width: 370px;
  }

  @media (min-width: 1200px) {
    width: 440px;
  }

  // @media (min-width: 1400px) {
  //   width: 450px;
  // }

  // @media (min-width: 1440px) {
  //   width: 450px;
  // }

  @media (min-width: 1536px) {
    width: 540px;
  }

  @media (min-width: 1600px) {
    width: 540px;
  }

`;


export const ContactIconImage = styled.img`
${tw`w-[15vh] h-auto `}
`

export const TitleDash = styled.hr`
${tw`h-[3px] w-[20%] rounded-lg`}
background:${palette.primary}
`


export const Text = styled.p`
  ${tw`2xl:text-2xl lg:text-sm w-full font-bold mt-3 mb-1`}
  color:${palette.white}
`;



export const ContactContainer = styled.div`
  ${tw`flex flex-col w-[400px] h-[400px] items-center m-auto my-10 justify-center items-center
  sm:w-[450px] sm:h-[450px]
  md:w-[500px] md:h-[500px]
  xl:w-[600px] xl:h-[600px]
  `}
  /* top: 120px; */
  // margin-bottom: 10px;
  margin-top: 46px;
  background: linear-gradient(360deg, #389dfc, #025099);
  border-radius: 100%;
  margin-top: 0px;
`;
