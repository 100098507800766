import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
//   const { pathName } = useLocation();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  return null;
}
