import React, { useEffect, useState } from 'react';
import { Tooltip, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { palette } from '../styles/palette';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  toTopButton: {
    position: 'fixed',
    bottom: 150,
    right: 20,
    zIndex: 800,
    opacity: 0,
    transition: 'opacity 0.3s, transform 0.3s',
    pointerEvents: 'none',
    transform: 'translateY(100%)',
  },
  showButton: {
    opacity: 1,
    pointerEvents: 'auto',
    transform: 'translateY(0)',
  },
  tooltipText: {
    color: `${palette.primary}`,
    backgroundColor: 'white',
    boxShadow: '0 0 10px -3px black',
    '&::before': {
      boxShadow: '-5px 5px 10px 0 black',
    },
    padding: '10px',
    fontSize: '15px',
  },
  tooltipArrow: {
    color: 'white',
    width: '20px',
    height: '20px',
    '&::before': {
      borderWidth: '10px 10px 10px 0',
    },
  },
}));

const ScrollToTop = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 500) {
        // Show button after scrolling past a threshold
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll); // Cleanup
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Tooltip
        title="Scroll to Top"
        arrow
        placement="left"
        classes={{ tooltip: classes.tooltipText, arrow: classes.tooltipArrow }}
      >
        <Fab
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          color="primary"
          aria-label="scroll-to-top"
          className={`${classes.toTopButton} ${
            isVisible ? classes.showButton : ''
          }`}
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon sx={{ width: 30, height: 40 }} />
        </Fab>
      </Tooltip>
    </>
  );
};

export default ScrollToTop;
