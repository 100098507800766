import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';
import { NavLink as Link } from 'react-router-dom';


export const TabCont = styled.ul`
  ${tw`text-start list-none absolute top-[85px]  mr-10 `}
`;

export const TabContSub = styled.li`
  ${tw`cursor-pointer shadow-2xl`}
  background:${palette.white}
`;

export const LinkTag2 = styled(Link)`
  ${tw`block w-[100%] p-3 font-bold h-[100%] no-underline`}
  color:${palette.primary}
`;