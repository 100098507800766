import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import {
  CardStyle,
  MainTeamContainer,
  TitleContMain,
  TeamsDesc,
  ContainerB,
  GridContainer,
} from '../styles/TeamStyle';

import ScrollToTop from './ScrollToTop';

import { Title, TitleDash, TitleHeadComCont } from '../styles/CommonStyles';

const Team = (scrollToTop, classes, isVisible) => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    axios.get('https://leapot-lms-backend.onrender.com/api/getteamdata')
      .then(response => {
        setTeamData(response.data.teams);
      })
      .catch(error => {
        console.error('Error fetching team data:', error);
      });
  }, []);

  return (
    <MainTeamContainer>
      <TitleContMain>
        <TitleHeadComCont>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="300"
            data-aos-delay="150"
          >
            Our Team
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="200"
          />
        </TitleHeadComCont>
      </TitleContMain>

      <ContainerB>
        <TeamsDesc>
          Welcome to Leapot Team. Our team is always pushing the envelope in
          terms of innovation to provide you with state-of-the-art solutions
          that promote efficiency and growth.
        </TeamsDesc>
        <GridContainer>
          <Grid
            container
            align="center"
            gap={5}
            spacing={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            {teamData.length > 0 &&
              teamData.map((item, index) => (
                <Grid item xs={12} sm={5} md={4} lg={3} key={index}>
                  <Card
                    style={CardStyle}
                    sx={{
                      maxWidth: 400,
                      minHeight: 400,
                      width: '350px',
                    }}
                  >
                    <CardContent
                      sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        alt=""
                        src={item.image}
                        style={{
                          height: '210px',
                          width: '210px',
                          aspectRatio: 3 / 2,
                          borderRadius: '100%',
                          margin: '5px',
                          border: '5px solid #036ACB',
                          pointerEvents: 'none',
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '500',
                          color: '#036ACB',
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'start',
                          fontSize: '20px',
                          marginTop: '30px',
                        }}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'medium',
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                        gutterBottom
                        variant="h7"
                        component="div"
                      >
                        {item.designation}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </GridContainer>
      </ContainerB>
      <ScrollToTop
        isVisible={isVisible}
        scrollToTop={scrollToTop}
        classes={classes}
      />
    </MainTeamContainer>
  );
};
export default Team;
