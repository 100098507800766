import React, { useState, useEffect } from 'react';
import {
  MainContainer,
  HeadingConatiner,
  CarouselContainer,
  GridCardContainerSub,
} from '../../styles/TestimonialStyle';

import { Title, TitleDash } from '../../styles/CommonStyles';

import TestimonialCard from './TestimonialCard';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <MainContainer>
      <HeadingConatiner>
        <Title>Words from Our Delighted Customers</Title>
        <TitleDash />
      </HeadingConatiner>
      <CarouselContainer>
        <GridCardContainerSub>
          <TestimonialCard />
        </GridCardContainerSub>
      </CarouselContainer>
    </MainContainer>
  );
};

export default Testimonials;
