export const palette = {
  footer: '#F7F7F7',
  form_bg: '#F6F8FF',
  bgColor1: '#ffffff',
  bgColor2: '#FFFFF0',
  bgColor3: '#F9F8F8',
  newColor: '#f2f6f9',
  // New color palette
  primary: '#036ACB',
  secondary: '#0684FB',
  tertiary: '#389DFC',
  heading: '#0A0959',
  gray: '#CCCCCC',
  light_gray: '#E6E6E6',
  white: '#ffffff',
  black: '#000000',
  buttonhover: '#6AB6FD',
  success: '#007E33',
  failure: '#CC0000',
  moderate: '#FF8800',
  Calendar_highlight: '#6ab6fd',
  Calendar_bgdate: '#fff',
};

export const theme = {
  themeBorderRadius: '25px',
};
