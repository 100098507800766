import React from 'react';

import {
  CSRContainer,
  Container2,
  TitleSub,
  DescriptionMain,
  ImgCont,
} from '../../styles/CSRStyle';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

import csr from '../../images/csr.jpg';

const CSR = () => {
  return (
    <CSRContainer>
      <TitleHeadComCont>
        <Title>CSR Activities</Title>
        <TitleDash />
      </TitleHeadComCont>
      <TitleSub>
        Elevating Our Impact Through Corporate Social Responsibility (CSR)
        Activities!
      </TitleSub>
      <Container2>
        <DescriptionMain>
          At Leapot Technologies, we believe that businesses have a
          responsibility to not only excel in their industries but also
          contribute positively to society. That's why we're committed to
          embracing Corporate Social Responsibility (CSR) initiatives that make
          a difference in our communities and beyond.
          <br />
          <br />
          CSR activities are crucial because they allow us to give back, support
          important causes, and create a meaningful impact beyond our core
          business objectives. By engaging in CSR, we can address social,
          environmental, and economic challenges, foster positive relationships
          with stakeholders, and build a more sustainable future for all.
          <br />
          <br />
          From supporting education and environmental conservation to
          championing diversity and inclusion, our company is dedicated to
          making a difference in areas that matter most. We believe that by
          leveraging our resources, expertise, and passion, we can inspire
          positive change and leave a lasting legacy that extends far beyond the
          bottom line.
          <br />
          <br />
          Stay tuned for updates on our upcoming CSR activities and join us in
          making a difference together. Together, we can create a brighter and
          more inclusive future for everyone
          <br />
        </DescriptionMain>
        <ImgCont src={csr} />
      </Container2>
    </CSRContainer>
  );
};

export default CSR;
