import React, { useEffect } from 'react';
import {
  MainProductContainer,
  ProductDescMain,
  ProductHeaderCont,
  CardContentCont,
  CardsImg,
  ProductHeadContSub,
  GridContainer,
  ProductHeadMain,
  ProductHeadDesc,
  ProductHeadDesc2,
  TitleCard,
  Content3,
  Content3Desc,
  TitleCont,
  Description,
  Content3Img,
  Content3Img2,
  Content3Img2B,
  ImgContOne,
  ImgContTwo,
  ImgContThree,
} from '../../styles/ProductStyle';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

import pr from '../../images/landingPageProductsImage.jpg';
import pr1 from '../../images/eventManger2.jpg';
import pr2 from '../../images/EventCalendar3.jpg';
import pr3 from '../../images/ExamGuard2.jpg';

import ScrollToTop from '../ScrollToTop';

import { Grid } from '@mui/material';

import AOS from 'aos';
import 'aos/dist/aos.css';

function Product(scrollToTop, classes, isVisible) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <MainProductContainer>
        <ProductHeadMain>
          <TitleHeadComCont>
            <Title
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="300"
              data-aos-delay="150"
            >
              Products
            </Title>
            <TitleDash
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <ProductHeadDesc2>
            Discover a comprehensive suite of cutting-edge solutions tailored to
            propel businesses forward in the digital age, exclusively at Leapot
            Technologies. Our portfolio encompasses a diverse array of products
            meticulously crafted to address the evolving needs of modern
            enterprises. Central to our offerings is a seamless integration of
            artificial intelligence, empowering organizations with AI-generated
            courses, quizzes, and assessments designed to optimize learning
            outcomes.
          </ProductHeadDesc2>
        </ProductHeadMain>
        <ProductHeaderCont>
          <ProductHeadContSub>
            <ProductHeadDesc>
              Unlock Your Potential with Our Innovative Products - Where
              Excellence Meets Affordability!
            </ProductHeadDesc>
            <ProductDescMain>
              As industry pioneers, Leapot Technologies stands at the forefront,
              setting the standard for excellence across our product line. With
              a steadfast commitment to innovation and quality, our solutions
              redefine industry benchmarks and elevate operational efficiencies.
              Explore our market-leading products, each meticulously curated to
              deliver unparalleled performance and value.
              <br />
              <br />
              Join the countless businesses already benefiting from our
              transformative solutions and experience the Leapot advantage
              today.
            </ProductDescMain>
          </ProductHeadContSub>
          <GridContainer>
            <Grid
              container
              spacing={2}
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="700"
              data-aos-delay="300"
            >
              <Grid item xs={12} md={6}>
                <CardContentCont>
                  <CardsImg src={pr} />
                  <TitleCard>Leapot LMS</TitleCard>
                </CardContentCont>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContentCont>
                  <CardsImg src={pr1} />
                  <TitleCard>Leapot EventHub</TitleCard>
                </CardContentCont>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContentCont>
                  <CardsImg src={pr2} />
                  <TitleCard>Leapot EventNavigator</TitleCard>
                </CardContentCont>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContentCont>
                  <CardsImg src={pr3} />
                  <TitleCard>Leapot AI-ExamGuard</TitleCard>
                </CardContentCont>
              </Grid>
            </Grid>
          </GridContainer>
        </ProductHeaderCont>

        <Content3>
          <Content3Desc>
            <TitleCont>Leapot LMS</TitleCont>
            <Description>
              Our superliner product, the Leapot LMS, creates cutting-edge
              Learning Management Systems (LMS) that enable businesses to
              provide dynamic, interesting training courses. Our LMS systems are
              scalable, adaptable, and packed with cutting-edge features to
              improve our clients and user’s educational experiences. The LMS
              platform is created to make it easier to administer, track,
              report, and document training programs, educational courses, or
              learning and development efforts. Our VILT content is designed to
              deliver interactive and engaging training experiences, bridging
              the gap between in-person and virtual classrooms. Leapot LMS
              provides a wide range of key Features for User management, Course
              and content Organization, Assessments and Evaluations,
              Certifications, Communications and collaboration, and progress
              tracking and Reporting.
              <br />
              <br />
              So what are you waiting for, Unlock the Power of Knowledge with
              Our Dynamic Learning Management System!
            </Description>
          </Content3Desc>
          <ImgContOne>
            <Content3Img src={pr} />
          </ImgContOne>
        </Content3>
        <Content3>
          <ImgContTwo
            style={{
              border: '2px solid #0684fb',
              borderRadius: '10px',
              marginX: '10px',
            }}
          >
            <Content3Img2 src={pr1} />
          </ImgContTwo>
          <Content3Desc>
            <TitleCont>Leapot EventHub</TitleCont>
            <Description>
              Master Your Events with Our Cutting-Edge Events Manager: Simplify,
              Organize, and Execute with Ease. Leapot Events’ Manager is
              designed to streamline the planning, organization, and execution
              of events. An events manager simplifies the process of planning,
              organizing, and executing events, making it easier for organizers
              to create memorable and successful experiences for attendees. It
              offers a collective platform for organizing and managing various
              features of events such as Events title, description, Instructor
              name and ID, the meeting link, time, and course duration.
            </Description>
          </Content3Desc>
          <ImgContThree
            style={{
              border: '2px solid #0684fb',
              borderRadius: '10px',
              marginX: '10px',
            }}
          >
            <Content3Img2B src={pr1} />
          </ImgContThree>
        </Content3>
        <Content3>
          <Content3Desc>
            <TitleCont>Leapot EventNavigator</TitleCont>
            <Description>
              Introducing Leapot EventNavigator: Your Passport to Seamless Event
              Experiences! Navigate effortlessly through a world of upcoming
              events, detailed schedules, and hassle-free registrations—all in
              one dynamic platform. With our intuitive calendar interface,
              registering, paying, and managing your event lineup is a breeze.
              Join the excitement, mark your calendar, and unlock unforgettable
              experiences with Leapot EventNavigator today!
              <br />
              <br />
              Welcome to our schedule of events! Our Dynamic Leapot
              EventNavigator is the Ultimate Event Management Solution to Help
              You Stay Ahead of the Curve. Keep abreast of all the fun events
              and activities that are scheduled. You may discover everything
              you're looking for right here, including educational webinars,
              training lessons, captivating workshops, and enjoyable community
              events. Leapot’s Event Calendar presents a sequence or grid of
              events organized by date, providing users with information such as
              event titles, dates, times, descriptions, and any other relevant
              details.
              <br />
              <br />
              So why do you hold off? Mark up your calendar and join us for
              upcoming events.
            </Description>
          </Content3Desc>
          <ImgContOne
            style={{
              border: '2px solid #0684fb',
              borderRadius: '10px',
              marginX: '10px',
            }}
          >
            <Content3Img src={pr2} />
          </ImgContOne>
        </Content3>
        <Content3>
          <ImgContTwo
            style={{
              border: '2px solid #0684fb',
              borderRadius: '10px',
              marginX: '10px',
            }}
          >
            <Content3Img2 src={pr3} />
          </ImgContTwo>
          <Content3Desc>
            <TitleCont>Leapot AI-ExamGuard</TitleCont>
            <Description>
              Introducing Leapot AI-ExamGuard by Leapot Technologies: Elevate
              Your Learning Experience with Our AI-Powered Assessment Platform.
              Our comprehensive solution offers advanced features for creating
              and administering assessments, quizzes, assignments, and exams
              with ease. With the power of AI,our quiz and exam question
              generator ensures diverse and engaging content, enriching the
              learning process for students. Moreover, our innovative proctoring
              feature provides secure exam environments, safeguarding the
              integrity of assessments. Admins can effortlessly assign grades,
              track learner progress, and generate performance reports, all
              within the intuitive Leapot LMS interface. From multiple-choice to
              essay questions, our platform supports various assessment types,
              allowing instructors to design exams tailored to their curriculum.
              With real-time tracking and analysis, educators can monitor
              learners' engagement, progress, and performance, enabling
              personalized learning experiences. Join us on the journey to
              enhanced education with Leapot AI-ExamGuard.
            </Description>
          </Content3Desc>
          <ImgContThree
            style={{
              border: '2px solid #0684fb',
              borderRadius: '10px',
              marginX: '10px',
            }}
          >
            <Content3Img2B src={pr3} />
          </ImgContThree>
        </Content3>

        <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        />
      </MainProductContainer>
    </>
  );
}

export default Product;
