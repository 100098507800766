import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';

export const BlogContainer = styled.div`
  ${tw` mx-8 mt-[75px] mb-10 flex justify-center items-center flex-col`}
`;

export const MainTitle = styled.h1`
  ${tw`flex justify-center items-center`}
  color:${palette.primary}
`;

export const HeaderImage = styled.div`
  ${tw`relative w-[100%] bg-cover  bg-no-repeat rounded-lg flex justify-center items-center mt-10`}
  background : ${(props) => `url(${props.bgImage})`};
  min-height: 600px;
  height: 100%;
  background-size: cover;
  // top: 40px;
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-black opacity-50`}
  }
`;

export const TitleHeading = styled.h1`
  ${tw`flex justify-center items-center text-center text-sm
  sm:text-xl mx-[-200px] mr-[-20px]
  md:text-2xl mx-[-20px]
  lg:text-3xl 
  xl:text-4xl
  2xl:text-4xl`}
  color: ${palette.white};
`;

export const MainHeading = styled.div`
  /* ${tw`flex justify-center items-center mt-[-50px] text-4xl 
  sm:text-xl
  md:text-2xl
  lg:text-3xl
  xl:text-4xl
  `}
  color:${palette.primary} */
  ${tw`z-1`}
`;

export const Header = styled.h1`
  ${tw`flex justify-center items-center mt-14 text-4xl ml-5
  sm:text-xl
  md:text-2xl
  lg:text-3xl
  xl:text-4xl
  2xl:text-4xl`}
  color: ${palette.white}
`;

export const MainCardConatiner = styled.div`
  ${tw` flex justify-center items-center flex-col `}
`;

export const Heading = styled.h1`
  ${tw`flex justify-center  items-center mt-44 `}
  color: ${palette.primary}
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[100%] flex justify-center items-center rounded-lg mb-5`}
  background:${palette.primary}
`;







export const BlogParagraph = styled.p`
${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
color: ${palette.black};
`


export const HeadCont = styled.div`
${tw`flex justify-center mb-5 items-center flex-col`}
`



///////////////////////////


export const CardStyle = {
  boxShadow: 'none',
  cursor: 'pointer',

  '&:hover': {
    // Add styles you want to apply on hover
    // For example:
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    transition: 'box-shadow 0.3s ease',
    backgroundColor: 'blue',
  }
};