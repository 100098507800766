import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";

export const ServiceContainer = styled.div`
${tw`mt-[120px] flex justify-center items-center m-24`}
`;

export const Container2 = styled.div`
  ${tw`relative flex flex-col justify-center items-center `}
`;

export const Content2 = styled.div`
  ${tw`flex justify-center items-center flex-col mb-20 mt-[-25px]`}
  width: 100%;
`;


export const ContMain = styled.div`
  ${tw`mt-[-50px]`}
`;






export const Content3 = styled.div`
  ${tw`flex justify-center items-center flex-col mb-24
  lg:flex-row
  `}
  width: 100%;
  // border: 1px solid gray;
`;


export const Content3Img = styled.img`
  ${tw`w-[100%] h-auto rounded-xl 
  lg:w-[100%] lg:h-[40vh] lg:aspect-[3/2] lg:object-contain
  `}
  // border: 2px solid #0684fb;
`;

export const ImgContOne = styled.div`
${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
lg:w-[40%] lg:ml-5
`}
border: 2px solid #0684fb;
`

export const ImgContTwo = styled.div`
${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
lg:hidden lg:ml-5
`}
border: 2px solid #0684fb;
`


export const Content3Img2B = styled.img`
  ${tw`w-[100%] h-auto rounded-xl
  lg:hidden
  `}
  // border: 2px solid #0684fb;
`;



export const Content3Img2 = styled.img`
  ${tw`hidden w-[35vw] h-[42vh] lg:aspect-[3/2] lg:object-contain rounded-xl mr-5
  lg:block
  `}
  border: 2px solid #0684fb
`;






export const Content3Desc = styled.div`
  ${tw`w-[80vw]
  lg:w-[45vw]
  `}
`;




export const TitleCont = styled.h1`
  ${tw` font-medium text-lg 2xl:text-3xl xl:text-xl lg:text-xl md:text-xl  sm:text-lg leading-4 `}
  color: ${palette.primary};
`;


export const Title = styled.h1`
  ${tw` font-bold text-lg 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-xl sm:text-lg leading-4 `}
  color: ${palette.primary};
`;


export const TitleSub = styled.h1`
  ${tw` font-bold mt-5 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
`;

export const DescriptionMain = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`;


export const Description = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-6  tracking-wide`}
  color: ${palette.black};
  line-height: 40px;
`;


export const TitleDash = styled.hr`
${tw`h-[3px] w-[100%] rounded-lg mb-5`}
background:${palette.primary}
`





