import React from 'react';
import { Link } from 'react-router-dom';
import {
  CareersCont,
  CareersTitle,
  CareersParagraph,
  CareersParagraph2,
  ContainerMain,
  Container1,
  Container2,
  Container3,
  Container4,
  ImgOne,
  ImgTwo,
  CareersTitle2,
  MailTag,
} from '../../styles/CareersStyle';
import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';
import Filterjobs from '../filterComps/Filterjobs';
import I1 from '../../images/careerImg1.jpg';
import I2 from '../../images/careerImg2.jpg';

import ScrollToTop from '../ScrollToTop';

const Careers = (scrollToTop, classes, isVisible) => {
  return (
    <>
      <CareersCont>
        <TitleHeadComCont>
          <Title
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="150"
          >
            Career
          </Title>
          <TitleDash
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="150"
          />
        </TitleHeadComCont>
        <CareersTitle>Career at Leapot Technologies</CareersTitle>
        <CareersParagraph>
          At Leapot Technologies, we believe in fostering a remote-friendly work
          environment that empowers our employees to nurture their careers while
          maintaining a healthy work-life balance. Our commitment to remote work
          means that you have the flexibility to work from anywhere, whether
          it's from the comfort of your home, a bustling coffee shop, or a
          serene beachside retreat. This flexibility enables our team members to
          tailor their work environment to suit their individual needs and
          preferences, resulting in greater productivity and satisfaction.
        </CareersParagraph>
        <br />
        <ImgOne src={I1} />
        <br />
        <CareersParagraph>
          Life and career at Leapot Technologies are characterized by
          innovation, collaboration, and growth. As part of our team, you'll
          have the opportunity to work alongside talented individuals from
          diverse backgrounds, contributing your unique skills and ideas to
          exciting projects that push the boundaries of technology. Whether
          you're a seasoned professional or just starting your career journey,
          we provide a supportive and inclusive environment where you can thrive
          and achieve your full potential. Join us at Leapot Technologies and
          embark on a rewarding career journey where your aspirations are
          nurtured, and your work-life balance is respected.
        </CareersParagraph>
        <br />
        <CareersTitle>Current Openings</CareersTitle>
        <CareersTitle>
          Kickstart Your Career with Our Internship Opportunities!
        </CareersTitle>
        <CareersParagraph>
          Are you eager to gain hands-on experience and kickstart your career in
          the dynamic world of product development, software engineering, HR,
          marketing, content development, and beyond? Join our internship
          program at Leapot Technologies and embark on a journey of growth and
          discovery. As an intern with us, you'll have the chance to work
          alongside industry experts in various fields, contributing to
          impactful projects that drive innovation and success. Whether you're
          passionate about coding, design, human resources, marketing strategy,
          or content creation, we offer diverse internship opportunities to help
          you explore your interests and hone your skills. If you're a motivated
          and ambitious individual ready to take the first step toward a
          rewarding career, we invite you to explore our internship openings
          below. Don't miss this chance to gain invaluable experience and pave
          the way for a bright future with Leapot Technologies. Apply now and

          join us in shaping the future of technology, learning, and beyond!
          <MailTag href="mailto:hr@leapot.in" > hr@leapot.in</MailTag>
        </CareersParagraph>
        <br />
        <ContainerMain>
          <Container1>
            <Container2>
              <CareersTitle2>
                Join Our Dynamic Product Development Team!
              </CareersTitle2>
              <CareersParagraph2>
                At Leapot Technologies, innovation is at the heart of everything
                we do. Our Product Development Team is a diverse and talented
                group of individuals who collaborate to create cutting-edge
                solutions in the realm of Learning Management Systems (LMS).
                From frontend wizards crafting seamless user experiences to
                backend gurus architecting robust systems, our team members are
                passionate about pushing boundaries and delivering excellence.
                <br />
              </CareersParagraph2>
            </Container2>
            <br></br>
            <Container3>
              <CareersTitle2>
                Join Our Innovative eLearning Content Development Team!
              </CareersTitle2>
              <CareersParagraph2>
                Are you passionate about crafting engaging and impactful
                learning experiences? Our eLearning Content Development Team at
                Leapot Technologies is dedicated to creating dynamic educational
                content that captivates learners and drives results. From
                instructional design experts shaping curriculum to multimedia
                specialists bringing content to life, our team members are
                committed to pushing the boundaries of online education.If
                you're a creative thinker with a knack for storytelling and a
                drive to make a difference in the world of eLearning, we want to
                hear from you! Explore our current job openings below and embark
                on a rewarding journey with Leapot Technologies. Join us in
                shaping the future of education, one lesson at a time. Apply now
                <MailTag href="mailto:hr@leapot.in"> hr@leapot.in</MailTag>
              </CareersParagraph2>
            </Container3>
          </Container1>
          <Container4>
            <ImgTwo src={I2} />
          </Container4>
        </ContainerMain>

        <Filterjobs />
        <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        />
      </CareersCont>
    </>
  );
};

export default Careers;
