import React from 'react';
import {
  AboutUsMainConatiner,
  HeaderImage,
  MainHeading,
  TitleHeading,
  AboutUsContainer,
  Paragraph,
  AboutContainer,
  InnerContainer,
  InnerSubcontainer,
  Heading,
  Description,
  AboutImage1,
  Container,
  InnerContainer2,
  AboutImage1B,
  Container2,
  InnerContainer2B,
  InnerSubcontainer2B,
  AboutImageSub2,
  HeadingB,
  DescriptionB,
  HeadMain,
} from '../../styles/AboutusStyle';
import aboutUs from '../../images/prbg1.jpg';
import Reception1 from '../../images/Vision.jpg';
import Reception2 from '../../images/Mission.jpg';

import { Title, TitleDash, TitleHeadComCont } from '../../styles/CommonStyles';

import ScrollToTop from '../ScrollToTop';

export default function AboutUs(scrollToTop, classes, isVisible) {
  return (
    <>
      <AboutUsMainConatiner>
        <HeadMain>
          <TitleHeadComCont>
            <Title
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            >
              About us
            </Title>
            <TitleDash
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
        </HeadMain>
        <HeaderImage
          bgImage={aboutUs}
        >
          <MainHeading>
            <TitleHeading
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              Unveiling Our Vision,
              <br/>
              Fueling Your Inspiration
            </TitleHeading>
          </MainHeading>
        </HeaderImage>
        <AboutUsContainer>
          <TitleHeadComCont>
            <Title
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            >
              About Leapot
            </Title>
            <TitleDash
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <Paragraph>
            Welcome to Leapot Technologies! We are a dynamic team of young and
            talented entrepreneurs and engineers who are passionate about
            creating a better tomorrow. Founded in 2021, the company is driven
            by a groundbreaking idea and a vision to provide smart solutions
            that empower companies of all sizes.
            <br></br>
            <br></br>
            At Leapot Technologies, we specialize in revolutionizing education
            and training with our comprehensive suite of services. From our
            state-of-the-art Learning Management System to our expertly crafted
            Digital Content Development Solutions, we empower educational
            institutions, businesses, and organizations globally to thrive in
            the digital age. With a relentless dedication to innovation and a
            proven history of delivering excellence, we also offer top-tier
            Software Product Development and Staffing Services to ensure our
            clients stay ahead of the curve.
          </Paragraph>
        </AboutUsContainer>
        <AboutContainer>
          <TitleHeadComCont>
            <Title
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            >
              Our Vision and Mission
            </Title>
            <TitleDash
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <Container>
            <InnerContainer>
              <AboutImage1 src={Reception1} />
              <InnerSubcontainer>
                <Heading>Vision: Efficiency Empowers Excellence</Heading>
                <Description>
                  At Leapot Technologies, we envision a world where
                  technological innovation empowers individuals and
                  organizations to thrive. Through our cutting-edge solutions
                  and unwavering commitment to excellence, we strive to be a
                  catalyst for positive change, driving innovation, and
                  transforming the way businesses operate and people learn.
                </Description>
              </InnerSubcontainer>
            </InnerContainer>
            <InnerContainer2>
              <InnerSubcontainer>
                <Heading>Mission</Heading>
                <Description>
                  Leapot Technologies is dedicated to harnessing the power of
                  technology to create tailored solutions that meet the unique
                  needs of our clients. Through our custom software development,
                  eLearning content creation, staffing services, and innovative
                  products like Leapot LMS, we aim to streamline processes,
                  enhance productivity, and empower our partners to achieve
                  their goals efficiently and effectively. With a focus on
                  quality, innovation, and customer satisfaction, we are
                  committed to being a trusted partner in our clients' success
                  journeys.
                </Description>
              </InnerSubcontainer>
              <AboutImage1B src={Reception2} />
            </InnerContainer2>
          </Container>
          <Container2>
            <InnerContainer2B>
              <AboutImageSub2 src={Reception1} />
              <InnerSubcontainer2B>
                <HeadingB>Vision: Efficiency Empowers Excellence</HeadingB>
                <DescriptionB>
                  At Leapot Technologies, we envision a world where
                  technological innovation empowers individuals and
                  organizations to thrive. Through our cutting-edge solutions
                  and unwavering commitment to excellence, we strive to be a
                  catalyst for positive change, driving innovation, and
                  transforming the way businesses operate and people learn.
                </DescriptionB>
              </InnerSubcontainer2B>
            </InnerContainer2B>
            <InnerContainer2B>
              <AboutImageSub2 src={Reception2} />
              <InnerSubcontainer2B>
                <HeadingB>Mission</HeadingB>
                <DescriptionB>
                  "Leapot Technologies is dedicated to harnessing the power of
                  technology to create tailored solutions that meet the unique
                  needs of our clients. Through our custom software development,
                  eLearning content creation, staffing services, and innovative
                  products like Leapot LMS, we aim to streamline processes,
                  enhance productivity, and empower our partners to achieve
                  their goals efficiently and effectively. With a focus on
                  quality, innovation, and customer satisfaction, we are
                  committed to being a trusted partner in our clients' success
                  journeys."
                </DescriptionB>
              </InnerSubcontainer2B>
            </InnerContainer2B>
          </Container2>
        </AboutContainer>
        <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        />
      </AboutUsMainConatiner>
    </>
  );
}
