import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';
import { NavLink as Link } from 'react-router-dom';
// import PRbg from '../images/PRbg.jpg';
import PRbg from '../images/prbg3.jpg';

export const HomeContainer = styled.div`
  ${tw` flex justify-center items-center flex-col`}
`;


export const OutCircle = styled.div`
  ${tw`relative flex justify-center items-center h-screen w-full`}
`;

export const InCircle = styled.div`
  ${tw`h-[150px] w-[60%] absolute top-[62%] left-0`}
  // background: ${palette.primary};
  background: linear-gradient(0.2deg, #036acb 4.8%, #6ab6fd 85.5%);
  border-top-left-radius: 100%;
  border-top-left-radius: 100%;
  transform: rotate(180deg);
`;

export const InCircleB = styled.div`
  ${tw`h-[100px] w-[40%] absolute top-[51%] right-0`}
  // background: ${palette.primary};
// background: linear-gradient(0.2deg, #6ab6fd 4.8%, #036acb 85.5%);
background: white;
  // background : linear-gradient( 90deg, #036ACB 0%, #389dfc 100% );
  border-top-left-radius: 100%;
  transform: rotate(360deg);
`;

export const NavLink = styled(Link)`
  ${tw`hidden lg:flex items-center font-medium cursor-pointer text-[20px] mx-5`}
  color: ${palette.primary};
  text-decoration: none;
  &:hover {
    // font-weight: bold;
    // transition: border-top-width 0.3s ease-in-out;
  }
  &.active {
    font-weight: bold;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    // transition: border-top-width 0.3s ease-in-out;
  }
`;

export const NavMenu = styled.div`
  ${tw`flex items-center justify-center mr-5`}
  md:hidden
`;

export const NavMenuCont = styled.div`
  ${tw`flex items-center justify-center`}
  md:hidden
`;

export const DropDownCont = styled.div`
  ${tw`hidden mt-2 ml-[-15px] mr-2
  lg:block
  `}
`;

export const DropdownArrow = styled.div`
  ${tw``}
`;

export const DropdownArrow2 = styled.div`
  ${tw`mt-[30px]`}
`;

export const UpArrowImg = styled.img`
  ${tw`h-4`}
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ visible }) => (visible ? 'rotate(0)' : 'rotate(180deg)')};
`;

export const DownArrowImg = styled.img`
  ${tw`h-4`}
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ visible }) => (visible ? 'rotate(0)' : 'rotate(180deg)')};
`;

export const DropDownContSub = styled.div`
  ${tw`ml-[-120px] cursor-pointer mt-[-10px] shadow-2xl`}
  position: absolute;
  top: ${({ visible }) => (visible ? '-15%' : '-15%')}; 
  left: 100;
  width: 100%;
  transition: top 0.4s ease; /* Adding transition for top property */
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;



export const TypicalCont = styled.div`
  ${tw`absolute m-5 text-4xl font-bold`}
`;

export const Landingheader = styled.div`
  ${tw`h-[62%] w-[100%] absolute`}
  // background: ${palette.primary};
  background: linear-gradient(0.2deg, #036acb 4.8%, #6ab6fd 85.5%);
  // border-top-right-radius: 100%;
`;

export const WaveEff = styled.div`
  ${tw`bg-red-600 h-10`}
`;

export const LogoImg = styled.img`
  ${tw`h-9 m-5 mt-7 z-[1000] relative cursor-pointer`}
`;

export const NavCont = styled.div`
  ${tw`flex h-20 shadow-xl top-0 left-0 z-[1000] justify-between fixed bg-white w-[100%]`}
`;

// export const NavCont = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 80px; /* Adjust height as needed */
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   background-color: white;
//   z-index: 1000;
// `;



export const MainLandingContainerSub = styled.div`
  ${tw`mt-[-15px]`}
`;


export const ProductCardHead = styled.h2`
  ${tw``}
`;

export const ProductCardDesc = styled.p`
  ${tw` font-thin leading-relaxed`}
`;

export const ProductCardInfo = styled.div`
  ${tw`text-base font-bold text-blue-500 cursor-pointer`}
`;


export const GridCardContainerSub = styled.div`
  ${tw`mt-72 z-1 relative 
  sm:mt-52
  `}
`;

export const GridContainer = styled.div`
  ${tw`mt-7 sm:mt-0 w-[80vw] 
  md:w-[80vw] 
  lg:w-[75vw] 
  `}
`;


export const GridMain = {
  marginLeft: '-80px',
};

export const GridCards = {
  background: 'white',
  maxWidth: '700px',
  width: '100%',
  padding: '20px',
  boxShadow: '0px 5px 10px 0 gray',
  border: 'none',
  height: '100%',
  marginLeft: '10px',

  '@media (max-width: 1536px)': {
    maxWidth: '-500px', 
  },
  '@media (max-width: 1280px)': {
    maxWidth: '200px', 
  },
  '@media (max-width: 1024px)': {
    maxWidth: '200px', 
  },
  '@media (max-width: 768px)': {
    maxWidth: '200px', 
  },
  '@media (max-width: 640px)': {
    width: '800px',
  },
};


export const MainDescImg = styled.img`
  ${tw`hidden absolute h-[400px] rounded-full ml-10 z-1 top-[100px]
  md:hidden 
  lg:block lg:ml-[70%] lg:h-[270px] lg:w-[270px] lg:top-[70px]
  xl:h-[400px] xl:w-[400px] xl:ml-[60%]
  2xl:h-[360px] 2xl:w-[360px] 2xl:ml-[62%]
`}
`;

export const MainDescCont = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const NavSecond = styled.div`
  ${tw`absolute top-5 right-5 lg:hidden z-10 cursor-pointer`}
`;


export const ProductContainer = styled.div`
  ${tw`flex justify-center items-center p-0 rounded-xl w-[45vw] h-[100%]  
  sm:w-[55vw] sm:p-28
  md:w-[65vw] 
  lg:w-[70vw] 
`}
 
  background-image: url(${PRbg});
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 639px) {
    background-image: none;
  }
  @media (min-width: 640px) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
  }
`;



export const ProductContSub = styled.h4`
  ${tw`flex absolute justify-center text-center flex-col items-center w-[80%] ml-[-65px] z-1 mb-28 
xl:justify-center xl:items-center 
lg:justify-center lg:items-center 
md:justify-center md:items-center
sm:mx-10
`}
`;


export const ProductHeadB = styled.p`
  ${tw` flex text-lg justify-center  text-justify items-center leading-relaxed 
  sm:text-white
  xl:text-3xl
   `}
`;

export const ProductDesc = styled.p`
  ${tw`text-sm flex justify-center text-justify items-center font-thin leading-relaxed
sm:text-sm sm:text-white
md:text-base
xl:text-xl
`}
`;

export const MailContMain = styled.div`
  ${tw`mt-5 flex`}
`;

export const MailCont = styled.input`
  ${tw`w-[250px] p-3
xl:w-[300px]
`}
`;

export const SendBtn = styled.button`
  ${tw`p-3 w-[100px] border-none  text-white text-[15px]`}
  background : ${palette.primary}
`;


export const SideMenu = styled.div`
  ${tw`fixed top-0 right-0 bg-white transition z-10`}
  width: 200px;
  height: 100%;
  transition: right 0.4s ease-in-out;
  right: ${({ open }) => (open ? '0' : '-250px')};
`;

export const NavMenuB = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px
`;

export const NavMenuC = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top:100px;
`;

export const NavMenuBLink = styled(Link)`
  ${tw`text-lg z-10 block py-2 px-4 mt-4 font-medium text-[#0684fb] transition-colors duration-300 hover:text-red-600`}
  text-decoration: none;
`;

export const NavMenuBLink2 = styled(Link)`
  ${tw`text-lg z-10 block py-1 px-4 mt-4 font-medium text-[#0684fb] transition-colors duration-300 hover:text-red-600`}
  text-decoration: none;
`;


export const HeaderImage = styled.img`
  ${tw`w-[100%] h-[700px] shadow-xl`}
`;


export const ProductHeading = styled.div`
  ${tw`flex justify-center items-center flex-col mb-0 mt-28
  sm:mb-16
  `}
`;

export const Title = styled.h1`
  ${tw`text-xl`}
  color:${palette.primary}
`;


export const CardContentCont = styled.div`
  ${tw`flex justify-center items-start flex-col h-[100%] `}
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  // border:2px solid ${palette.secondary};
  background: white;
  transition: border-width 0.3s ease;

  &:hover {
    border-width: 5px;
    border-color: ${palette.secondary};
  }
`;

export const DescCont = styled.div`
  ${tw`flex justify-start items-start flex-col m-4`}
`;


export const ProductContHeadMain = styled.div`
  ${tw`mt-20
  sm:mt-0
  `}
`;

export const TitleSub = styled.h2`
  ${tw`font-medium text-white`}
  color:${palette.primary}
`;


export const ProductPara = styled.p`
  ${tw`font-medium text-sm text-justify tracking-wide leading-8 mt-[0px]
  md:text-sm md:leading-8
  lg:text-sm
  xl:text-[19px]
`}
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-52 rounded-lg`}
  background:${palette.primary}
`;
