import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";

export const MainProductContainer = styled.div`
  ${tw`mt-[120px] m-10 sm:m-20 sm:mt-24
`}
`;

export const ServiceHead = styled.h1`
${tw`text-4xl font-bold`}
color : ${palette.primary}
`

export const ProductHeadingSub = styled.h2`
${tw`text-2xl font-bold text-white`}
`


export const ProductDescMain = styled.p`
${tw`text-base text-white text-justify leading-8
xl:text-xl
`}
`

export const ProductContainerA = styled.p`
${tw`text-xl flex m-10 w-fit`}
`
export const ProductContainerAImg = styled.img`
${tw`hidden h-[50vh] w-[60vh]
    lg:block
`}
`
export const ProductContainerADesc = styled.p`
${tw`flex justify-center items-start flex-col`}
`
export const ProductHeadingA = styled.p`
${tw`text-3xl font-bold`}
Color:${palette.primary}
`


export const ProductHeaderCont = styled.p`
${tw`flex flex-col bg-[#0684fb] justify-center items-center w-[100%] mt-10 
lg:flex-row
`}
border-radius:20px

`

export const ProductHeaderDesc = styled.p`
${tw``}
`

export const ProductHeading = styled.h1`
${tw`text-4xl font-bold`}
color : ${palette.primary}
`

export const TitleDash = styled.hr`
${tw`h-[3px] w-[10%] rounded-lg mb-5`}
background:${palette.primary}
`

export const ProductHeadMain = styled.div`
${tw`flex justify-center items-center flex-col`}
`

export const ProductHeadDesc = styled.div`
${tw`text-lg text-white font-medium text-lg
md:text-xl
lg:text-2xl
`}
`

export const ProductHeadDesc2 = styled.div`
  ${tw`font-thin mt-5 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`


export const ProductHeadMainSub = styled.div`
${tw`flex justify-center items-center flex-col
`}
`

export const ProductDesc = styled.div`
${tw`text-lg text-justify leading-8 w-[60vw] 
    lg:text-base lg:w-[35vw]
    md:text-lg md:leading-8 md:w-[75vw]
    xl:text-xl xl:leading-8 xl:w-[55vw]
`}
`


export const CardContentCont = styled.h1`
${tw`flex justify-center items-center flex-col bg-white`}
border: none; 
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
border-radius: 8px; 
`


export const CardsImg = styled.img`
${tw`h-[150px] w-auto flex justify-center items-center mb-10 mt-10`}
`

export const TitleCard = styled.h1`
${tw`text-xl`}
color:${palette.primary}
`

export const MoreInfoBtn = styled.p`
${tw`text-sm`}
color:${palette.primary}
`

export const ProductHeadContSub = styled.div`
${tw`flex justify-center items-center flex-col w-[65vw] md:w-[50vw] m-5`}
`

export const GridContainer = styled.div`
${tw`m-5`}
`


export const Content3 = styled.div`
  ${tw`flex justify-center items-center flex-col mb-24 mt-10
  lg:flex-row
  `}
  width: 100%;
  // border: 1px solid gray;
`;

export const Content3Desc = styled.div`
  ${tw`w-[80vw]
  lg:w-[45vw]
  `}
`;

export const TitleCont = styled.h1`
  ${tw` font-medium text-lg 2xl:text-3xl xl:text-xl lg:text-xl md:text-xl  sm:text-lg leading-4 `}
  color: ${palette.primary};
`;

export const Description = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify 
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-8  tracking-wide`}
  color: ${palette.black};
  line-height: 40px;
`;

export const Content3Img = styled.img`
  ${tw`w-[60%] h-auto rounded-xl 
  sm:ml-0 sm:h-[50vh]
  md:ml-0 
  lg:w-[60%] lg:h-auto 
  `}
  // border: 2px solid #0684fb;
`;


export const Content3Img2 = styled.img`
  ${tw`hidden w-[40vw] h-[38vh] rounded-xl mr-20
  md:h-[50vh]
  lg:block lg:w-[60%] lg:h-auto 
  `}
  // border: 2px solid #0684fb
`;

export const Content3Img2B = styled.img`
  ${tw`w-[100%] h-auto rounded-xl
  md:h-[50vh]
  lg:hidden lg:w-[40%] lg:h-auto
  `}
  // border: 2px solid #0684fb;
`;

export const ImgContOne = styled.div`
${tw`flex justify-center items-center w-[100%] h-auto mx-10 rounded-xl
lg:w-[40%] lg:h-[47vh]
`}
border:2px solid #036ACB;
`

export const ImgContTwo = styled.div`
${tw`hidden lg:flex justify-center items-center w-[40%] h-[45vh] mr-10`}
`
export const ImgContThree = styled.div`
${tw`lg:hidden lg:flex justify-center items-center w-[100%] ml-10 h-auto mr-10`}
`
