import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from './palette';

export const MainContainer = styled.div`
  ${tw` mt-20  flex justify-center items-center flex-col`}
  // background-color: ${palette.primary};
`;

export const HeadingConatiner = styled.div`
  ${tw`flex justify-center items-center text-center flex-col`}

`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[38%] rounded-lg mt-[-7px] text-4xl`}
  background:${palette.white}
`;


export const CarouselContainer = styled.div`
${tw`rounded-lg mx-0 mb-20 `}
// background:${palette.primary}
  /* max-width: 500px; */
`;

export const GridCardContainerSub = styled.div`
  ${tw` flex justify-center items-center mx-5 sm:mx-20 mt-10`}
`;

export const GridCardContainer = styled.div`
  ${tw`m-5`}
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row by default */
  gap: 30px;

  max-width: 900px; /* Set a max-width for the grid */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One card per row for smaller screens */
  }
`;

export const SubCardContainer = styled.div`
  ${tw`flex justify-start items-center flex-row `}
`;

export const InnerSubContainer = styled.div`
  ${tw`flex justify-start items-start flex-col ml-[18px]`}
`;

export const TestimonialStyle = {
  borderRadius: '6%',
  height: '100%',
  gap: '50px',
  // boxShadow: '0px 0px 7px 0px grey',
  /* padding: '4px', */
  margin: '3px',
  /* borderStyle: 'solid', */
};

export const CardsStyle = {
  maxWidth: 700,
  minheight:400, 
  display:'flex', 
  justifyContent:'center', 
  alignItems:'center', 
  flexDirection:'column',
  // backgroundColor:`${palette.secondary}`,
  borderRadius:'10px',
  overflow: "visible",
  boxShadow:'none',
  marginTop:'0',

  '@media (min-width: 350px)': {
    marginTop: '0',
    marginBottom: '70px',
    height:'600px'
  },

  '@media (min-width: 375px)': {
    marginTop: '100px',
    marginBottom: '100px',
    height:'400px'
  },

  '@media (min-width: 400px)': {
    marginTop: '80px',
    marginBottom: '100px',
    height:'400px'
  },
  '@media (min-width: 500px)': {
    marginTop: '60px',
    marginBottom: '0',
    height:'450px'
  },
  '@media (min-width: 600px)': {
    marginTop: '60px',
    height:'450px'
  },
}

export const CardDesc = {
  fontFamily:'Roboto',
  fontWeight:'500',
  fontSize:'20px',
  height:'200px',
  textAlign: 'justify',

  '@media (max-width: 800px)': {
    fontSize:'15px',
  },

  '@media (min-width: 1600px)': {
    fontSize:'20px',
  },
}

export const CardTitle = {
  fontFamily:'Roboto',
  color:`${palette.primary}`,
  fontWeight:'500',
  fontSize:'30px'
}

export const CardDescCont = {
  height:'250px',
}






/// //////////////////

