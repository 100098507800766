import React from 'react';
import {
  FooterContainer,
  FooterText,
  FooterText2,
  Text,
  Container,

  LinkToFreepik,

  AttributionText,

} from '../../styles/FooterStyles';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleDisplayPrivacyPolicy = () => {
    navigate('/PrivacyPolicy');
  };
  const handleDisplayTerm = () => {
    navigate('/Terms');
  };
  const handleDisplayContactUs = () => {
    navigate('/Contactus');
  };

  return (

    <>
      <FooterContainer>
        <Container>
          <Text> Leapot Technologies </Text>
          <CopyrightIcon style={{color:'white'}}></CopyrightIcon>
          <Text>2023</Text>
        </Container>
        <FooterText onClick={handleDisplayPrivacyPolicy}>
          Privacy policy
        </FooterText>
        <FooterText onClick={handleDisplayTerm}>Terms of use</FooterText>
        <FooterText onClick={handleDisplayContactUs}>Contact us</FooterText>
        <FooterText2>
          All the images in the site are designed by{' '}
          <LinkToFreepik
            href="https://www.freepik.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Freepik
          </LinkToFreepik>
        </FooterText2>
      </FooterContainer>
    </>

  );
};

export default Footer;
