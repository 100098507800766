import React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 50px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  @media (max-width: 640px) {
    width: 300px; // Adjust width for medium screens
  }

  @media (min-width: 640px) {
    width: 300px; // Adjust width for medium screens
  }

  @media (min-width: 768px) {
    width: 500px; // Adjust width for medium screens
  }

  @media (min-width: 992px) {
    width: 400px; // Adjust width for large screens
  }

  @media (min-width: 1200px) {
    width: 500px; // Adjust width for extra-large screens
  }

  @media (min-width: 1440px) {
    width: 500px; // Adjust width for screens larger than 1440px
  }
`,
);

const MinHeightTextarea = ({ value, onChange, ...rest }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Textarea
      placeholder="Type Message *"
      minRows={6}
      {...rest}
      value={value}
      onChange={handleChange}
    />
  );
};

export default MinHeightTextarea;
